import axios from "axios";
import { create } from "zustand";
import { apiUrl } from "../../Config";

export const useMindmapStore = create((set) => ({
  currentMap: null,
  mindmap: [],
  selectedMapId: null,

  setSelectedMapId: (id) => set({ selectedMapId: id }),

  createMap: async (payload) => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    try {
      // console.log(payload);
      const response = await axios.post(
        `${apiUrl}/mindmap/${userId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Mindmap added successfully', response.data);
      return response.data;
    } catch (err) {
      console.error('Failed to create mindmap', err);
      throw err;
    }
  },
  getMap: async () => {
    const userId = localStorage.getItem('userId');
    const projectId = localStorage.getItem('ProjectId')
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${apiUrl}/getmindmap/${userId}/${projectId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log('Mindmap fetched successfully:', response.data);
      return response.data; // Return the data as-is or adapt as needed
    } catch (err) {
      console.error('Failed to get mindmap:', err);
      return null;
    }
  },
  getMapId: async () => {
    const MapId = localStorage.getItem('mapId');
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/getmindmapBy/${MapId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.error('failed to fetch mindmap:', err);
      throw err;
    }
  },
  deleteMap: async (mapId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.delete(
        `${apiUrl}/deletemindmap/${mapId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
      );
      return response.data;
    } catch (err) {
      console.error('Failed to delete mindmap:', err.response ? err.response.data : err.message);
      throw err;
    }
  },
  updateMap: async (payload) => {
    const MapId = localStorage.getItem('mapId');
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${apiUrl}/updatemindmap/${MapId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      return response.data;

    } catch (err) {
      console.error('failed to update mindmap:', err);
      throw err;
    }
  },
  setCurrentmap: (mindmap) => set({ currentMap: mindmap })
}));