import React, { useState, useEffect } from 'react';
import { useMemberStore } from '../Store/MemberStore/MemberStore';
import { RxCross2 } from "react-icons/rx";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useAccountStore } from '../Store/AccountStore/AccountStore';

const InviteMemberModal = ({ closeModal }) => {
  const { createMember } = useMemberStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { userProfile } = useAccountStore();
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    password: ''
  });

  const [userType, setUserType] = useState(null); // Store user_type

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await userProfile();
        setUserType(profile?.user_type); // Get user_type from profile
      } catch (err) {
        console.error("Failed to fetch user profile:", err);
      }
    };
    fetchUserProfile();
  }, [userProfile]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Validate form fields
  const validateForm = () => {
    const { name, email, contact, password } = formData;
    
    // Check if name contains numbers
    if (/\d/.test(name)) {
      setError('Name should not contain numbers.');
      return false;
    }
    
    // Check contact number length
    if (contact.length !== 10 || !/^\d{10}$/.test(contact)) {
      setError('Contact number must be exactly 10 digits.');
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format.');
      return false;
    }

    // Validate password (at least 8 characters, 1 uppercase, 1 special character, and 1 number)
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password must be at least 8 characters long, includes (0-9),(A-Z,a-z),(!@#$%^&*)');
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    // Check if user is an admin
    if (userType !== 1) {
      setError('You are not authorized to invite members.');
      setLoading(false);
      return;
    }

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      await createMember(formData);
      setSuccess('Member Invited Successfully!');
      closeModal(); // Close modal on success
    } catch (err) {
      setError('Failed to invite member. Please try again.');
      console.error('API Error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="Invite-member">
      <div className="Invite-content">
        <div className='flex justify-between'>
          <h1>Invite member</h1>
          <span><RxCross2 onClick={closeModal} size={20} className='cursor-pointer' /></span>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Name *
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email *
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Contact Number *
            <input
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Password *
            <div className="password-input" style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                style={{ paddingRight: '30px' }}
              />
              <span
                onClick={togglePasswordVisibility}
                className="eye-icon"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '44%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </label>

          <div className="button-group">
            <button type="submit" disabled={loading}>
              {loading ? "Inviting..." : "Invite"}
            </button>
            <button type="button" onClick={closeModal}>
              Cancel
            </button>
          </div>
          {error && <p className="error" style={{ color: 'red' }}>{error}</p>}
          {success && <p className="success" style={{ color: 'green' }}>{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default InviteMemberModal;
