import React, { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import './main.css'
import Swal from 'sweetalert2';
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';
import { FiSquare } from 'react-icons/fi';
import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';

import CustomNode from './CustomNode';
const nodeTypes = {
    node: CustomNode,
}

const initialNodes = [
    {
        id: 'root',
        type: 'node',
        data: { label: 'Healthy Lifestyle', backgroundColor: 'pink' },
        position: { x: 500, y: 50 },
        style: { fontWeight: 'bold' }
    },
    {
        id: 'diet',
        type: 'node',
        data: { label: 'Diet', backgroundColor: '#FF6347' },
        position: { x: 200, y: 250 },

    },
    {
        id: 'exercise',
        type: 'node',
        data: { label: 'Exercise', backgroundColor: '#4682B4' },
        position: { x: 700, y: 250 },

    },
    {
        id: 'sleep',
        type: 'node',
        data: { label: 'Sleep', backgroundColor: '#32CD32' },
        position: { x: 200, y: 450 },

    },
    {
        id: 'mindfulness',
        type: 'node',
        data: { label: 'Mindfulness', backgroundColor: '#8A2BE2' },
        position: { x: 700, y: 450 },

    },
    {
        id: 'fruits',
        type: 'node',
        data: { label: 'Fruits & Vegetables', backgroundColor: '#FFB6C1' },
        position: { x: 100, y: 350 },

    },
    {
        id: 'protein',
        type: 'node',
        data: { label: 'Protein Sources', backgroundColor: '#F08080' },
        position: { x: 300, y: 350 },

    },
    {
        id: 'carbs',
        type: 'node',
        data: { label: 'Carbohydrates', backgroundColor: '#CD5C5C' },
        position: { x: 200, y: 400 },

    },

    {
        id: 'cardio',
        type: 'node',
        data: { label: 'Cardio', backgroundColor: '#87CEFA' },
        position: { x: 600, y: 350 },

    },
    {
        id: 'strength',
        type: 'node',
        data: { label: 'Strength Training', backgroundColor: '#4682B4' },
        position: { x: 800, y: 350 },

    },
    {
        id: 'flexibility',
        type: 'node',
        data: { label: 'Flexibility Exercises', backgroundColor: '#B0E0E6' },
        position: { x: 700, y: 400 },

    },

    {
        id: 'duration',
        type: 'node',
        data: { label: '7-9 hours/night', backgroundColor: '#98FB98' },
        position: { x: 100, y: 550 },

    },
    {
        id: 'quality',
        type: 'node',
        data: { label: 'Deep Sleep', backgroundColor: '#00FA9A' },
        position: { x: 300, y: 550 },

    },

    {
        id: 'meditation',
        type: 'node',
        data: { label: 'Meditation', backgroundColor: '#9370DB' },
        position: { x: 600, y: 550 },

    },
    {
        id: 'relaxation',
        type: 'node',
        data: { label: 'Relaxation Techniques', backgroundColor: '#8A2BE2' },
        position: { x: 800, y: 550 },

    },
    {
        id: 'hobbies',
        type: 'node',
        data: { label: 'Hobbies & Leisure', backgroundColor: '#BA55D3' },
        position: { x: 700, y: 600 },

    },
];

const initialEdges = [
    { id: 'e1-2', source: 'root', target: 'diet' },
    { id: 'e1-3', source: 'root', target: 'exercise' },
    { id: 'e1-4', source: 'root', target: 'sleep' },
    { id: 'e1-5', source: 'root', target: 'mindfulness' },
    { id: 'e2-6', source: 'diet', target: 'fruits' },
    { id: 'e2-7', source: 'diet', target: 'protein' },
    { id: 'e2-8', source: 'diet', target: 'carbs' },
    { id: 'e3-9', source: 'exercise', target: 'cardio' },
    { id: 'e3-10', source: 'exercise', target: 'strength' },
    { id: 'e3-11', source: 'exercise', target: 'flexibility' },
    { id: 'e4-12', source: 'sleep', target: 'duration' },
    { id: 'e4-13', source: 'sleep', target: 'quality' },
    { id: 'e5-14', source: 'mindfulness', target: 'meditation' },
    { id: 'e5-15', source: 'mindfulness', target: 'relaxation' },
    { id: 'e5-16', source: 'mindfulness', target: 'hobbies' },
];

const Healthymap = () => {
    const { createMap } = useMindmapStore();
    const [name, setName] = useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [selectedShape, setSelectedShape] = useState('rectangle');
   
    const saveMap = async () => {
        try {
            const mindmap = { nodes, edges };
            await createMap({ mindmap: mindmap, name: name });
            Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
        } catch (error) {
            console.error('Failed to save flowchart to API', error);
            Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
        }
    }
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const addNode = (shape) => {
        const newNode = {
            id: `${nodes.length + 1}`,
            type: 'node',
            data: {
                label: `node ${nodes.length + 1}`, shape: shape || selectedShape,
                backgroundColor: getRandomColor()
            },
            position: { x: 250, y: 250 },
            style: { backgroundColor: 'transparent' },
        };
        setNodes((nds) => nds.concat(newNode));
    };

    const addEdgeDynamically = () => {
        if (nodes.length < 2) {
            alert('Add at least two nodes to create an edge.');
            return;
        }
        const newEdge = {
            id: `e${nodes.length - 1}-${nodes.length}`,
            source: `${nodes.length - 1}`,
            target: `${nodes.length}`,
            
        };
        setEdges((eds) => eds.concat(newEdge));
    };

    const removeSelectedElements = () => {

        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedEdges = edges.filter((edge) => edge.selected);

        if (selectedNodes.length === 0 && selectedEdges.length === 0) {
            alert('Select a node or edge to remove.');
            return;
        }
        const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
        const updatedNodes = nodes.filter((node) => !node.selected);

        const updatedEdges = edges.filter(
            (edge) =>
                !selectedEdges.includes(edge) &&
                !selectedNodeIds.has(edge.source) &&
                !selectedNodeIds.has(edge.target)
        );

        setNodes(updatedNodes);
        setEdges(updatedEdges);
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
           <div className='map'>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >

                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow></div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Healthy Mindmap"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
            </div>
            <div className="responsive">
                <div className="buttons-container">
                    <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
                        <FiSquare size={20} />
                    </button>
                    <button onClick={() => addNode('oval')} title="Add Oval Node">
                        <FaRegCircle size={20} />
                    </button>
                    <button onClick={() => addNode('diamond')} title="Add Diamond Node">
                        <GoDiamond size={20} />
                    </button>
                    <button onClick={addEdgeDynamically} title="Add Edge">
                        <FaLink size={20} />
                    </button>
                    <button onClick={removeSelectedElements} title="Remove Selected">
                        <FaTrash size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} />
                    </button>
                   
                </div>
            </div>
        </div>
    );
};

export default Healthymap;


