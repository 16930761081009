import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTaskStore } from '../Store/TaskStore/TaskStore';

const EditTaskModel = ({ isOpen, onClose, taskToEdit, onUpdateTask }) => {
  const [task, setTask] = useState({
    title: '',
    subject: '',
    description: '',
    assign: ''
  });

  const {updateTask,setCurrentTask}=useTaskStore();

  useEffect(() => {
    if (taskToEdit) {
      setTask({
        title: taskToEdit.name,
        subject: taskToEdit.subject,
        description: taskToEdit.description,
        assign: taskToEdit.assign
      });
      setCurrentTask(taskToEdit);
    }
  }, [taskToEdit,setCurrentTask]);

  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
        // Prepare the updated task data
        await updateTask ( {
            name: task.title,
            subject: task.subject,
            description: task.description,
            assign: task.assign,
        });

     
        
        onUpdateTask(); // Notify the parent component to refresh tasks
        onClose(); // Close the modal
    } catch (error) {
        console.error('Failed to update task:', error);
    }
};


  return (
    isOpen ? (
      <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white p-6 rounded shadow-lg w-96 relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          >
            <IoClose size={24} />
          </button>
          <h2 className="text-xl font-semibold mb-4">Edit Task</h2>
          <input
            type="text"
            name="title"
            value={task.title}
            onChange={handleChange}
            placeholder="Task Name"
            className="w-full p-2 mb-2 border rounded"
          />
          <input
            type="text"
            name="subject"
            value={task.subject}
            onChange={handleChange}
            placeholder="Subject"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="description"
            value={task.description}
            onChange={handleChange}
            placeholder="Description"
            className="w-full p-2 mb-2 border rounded h-24 resize-none"
          />
          <select
            name="assign"
            value={task.assign}
            onChange={handleChange}
            className="w-full p-2 mb-2 border rounded text-gray-600"
          >
            <option value="">Select Assignee</option>
            <option value="User1">User 1</option>
            <option value="User2">User 2</option>
            <option value="User3">User 3</option>
            {/* Add more options as needed */}
          </select>
          <div className="flex justify-end">
            <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Update Task</button>
            <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default EditTaskModel;
