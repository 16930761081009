import React from 'react'
import Header from '../main folder/components/header/Header'
import Home from '../main folder/pages/home/Home'
import AboutUs from '../main folder/pages/about/AboutUs'
import Features from '../main folder/pages/featurs/Features'
import Footer from '../main folder/pages/footer/Footer'

const File = () => {
  return (
    <div>
    {/* <MainFile /> */}
    <div>
    {/* <NavBar /> */}
    <div className='custom-gradient '>
      <Header />
      <Home />
    </div>
    <div className='mt-4 ' style={{ position: 'relative' }}>
      <AboutUs />
    </div>
    <div className='mt-4 ' style={{ position: 'relative' }}>
      <Features />
    </div>
    {/* <FooterHead /> */}
    <div>
      <Footer />
    </div>
  </div>
   
  </div>
  )
}

export default File