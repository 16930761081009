import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import Select from 'react-select'; // Import react-select
import { useTaskStore } from '../Store/TaskStore/TaskStore';
import { useAccountStore } from '../Store/AccountStore/AccountStore';

const AddTaskModal = ({ isOpen, onClose }) => {
  const [task, setTask] = useState({
    title: '',
    subject: '',
    description: '',
    assign: [] 
  });
  const [error, setError] = useState('');
  const { createTask, getTasks } = useTaskStore();
  const { subUser } = useAccountStore();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    setLoading(true);
    try {
      const response = await subUser(); 
      const formattedMembers = response.map((member) => ({
        value: member.id, 
        label: member.name 
      }));
      setMembers(formattedMembers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  // Handle member selection in the multi-select dropdown
  const handleMemberChange = (selectedMembers) => {
    setTask({ ...task, assign: selectedMembers });
  };

  const handleSubmit = async () => {
    if (!task.title) {
      setError('Task Name is required.');
      return;
    }

    setError('');
    try {
      const projectId = localStorage.getItem('ProjectId');
      const assignedTo = task.assign.map((member) => member.value); // Get selected member IDs

      const newTask = {
        name: task.title,
        subject: task.subject,
        description: task.description,
        startdateandtime: new Date(),
        enddateandtime: new Date(),
        projectId,
        assignedTo, // Send selected member IDs to the backend
      };

      await createTask(newTask);
      setTask({ title: '', subject: '', description: '', assign: [] });
      await getTasks();
      onClose(); // Close the modal after task creation
    } catch (error) {
      console.error('Failed to create task:', error);
      setError('Failed to create task. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg w-96 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
        >
          <IoClose size={24} />
        </button>
        <h2 className="text-xl font-semibold mb-4">Add Task</h2>
        <input
          type="text"
          name="title"
          value={task.title}
          onChange={handleChange}
          placeholder="Task Name"
          className="w-full p-2 mb-2 border rounded"
        />
        <input
          type="text"
          name="subject"
          value={task.subject}
          onChange={handleChange}
          placeholder="Subject"
          className="w-full p-2 mb-2 border rounded"
        />
        <textarea
          name="description"
          value={task.description}
          onChange={handleChange}
          placeholder="Description"
          className="w-full p-2 mb-2 border rounded h-24 resize-none"
        />

        {/* Multi-select dropdown for members */}
        <Select
          isMulti
          name="assign"
          value={task.assign}
          onChange={handleMemberChange}
          options={members}
          isLoading={loading}
          placeholder="Select Assignees"
          className="w-full p-2 mb-2"
        />

        {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
        <div className="flex justify-end">
          <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">Add Task</button>
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AddTaskModal;
