import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useTeamStore } from "../Store/TeamStore/TeamStore";
import { useProjectStore } from "../Store/ProjectStore/ProjectStore";
import { useInviteStore } from "../Store/InviteStore/InviteMemberStore";
import { useAccountStore } from "../Store/AccountStore/AccountStore";

const CreateMembersModal = ({ togglePopup }) => {
  const [userType, setUserType] = useState("External");
  const [emails, setEmails] = useState("");
  const [internalMember, setInternalMember] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [loading, setLoading] = useState(false);
  // const  [selectedBoardId,setSelectedBoardId]=useState('');
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [accessLevel, setAccessLevel] = useState(0); 
  const { getTeam, teams } = useTeamStore();
  const { getProject, project } = useProjectStore();
  const { InviteUser } = useInviteStore();
  const { subUser } = useAccountStore();

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        await getTeam();
      } catch (error) {
        console.error("Failed to fetch teams:", error);
      }
    };

    const fetchMember = async () => {
      try {
        const users = await subUser();
        setInternalMember(users);
        console.log(users)
      } catch (error) {
        console.error("Failed to fetch members:", error);
      }
    };

    const fetchProjects = async () => {
      try {
        await getProject();
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchMember();
    fetchTeams();
    fetchProjects();
  }, [getTeam, getProject, subUser]);

  const validateEmails = (emails) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emails.split(",").every((email) => regex.test(email.trim()));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    setLoading(true); // Start loading

    // Validate based on user type
    if (userType === "External" && !validateEmails(emails)) {
      newErrors.email = "Please enter valid email addresses separated by commas.";
    }

    if (userType === "Internal" && !selectedMemberId) {
      newErrors.internalMember = "Please select an internal member.";
    }

    // Validate selected team and project
    if (!selectedTeamId) {
      newErrors.team = "Please select a team.";
    }

    if (!selectedProjectId) {
      newErrors.project = "Please select a project.";
    }

    const drawing = localStorage.getItem("drawingId");

    if (Object.keys(newErrors).length === 0) {
      try {
        if (userType === "External") {
          const emailList = emails.split(",").map((email) => email.trim());

          for (const email of emailList) {
            const payload = {
              UserType: userType,
              email,
              team: selectedTeamId,
              project: selectedProjectId,
              mode: accessLevel,
              board: drawing,
              token,
            };

            try {
              await InviteUser(payload); // Send the invite
            } catch (err) {
              const errorMessage = err.response?.data?.error || "Failed to send invitations. Please try again.";
              setErrors({ api: errorMessage });
              return; // Stop further processing if an error occurs
            }
          }
        } else if (userType === "Internal") {
          const payload = {
            project: selectedProjectId,
            team: selectedTeamId,
            board: drawing,
            member: selectedMemberId, // Send selected member's ID
            mode: accessLevel,
          };

          try {
            await InviteUser(payload); // Send the invite
          } catch (err) {
            const errorMessage = err.response?.data?.error || "Failed to send invitations. Please try again.";
            setErrors({ api: errorMessage });
            return;
          }
        }
        togglePopup(); // Close modal on successful invites
      } catch (err) {
        const errorMessage = err.response?.data?.error || "Something went wrong. Please try again.";
        setErrors({ api: errorMessage });
      } finally {
        setLoading(false); // End loading
      }
    } else {
      setErrors(newErrors); // Display validation errors
      setLoading(false); // End loading
    }
  };
  

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://whiteboard.colabrary.com/register/${token}`);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="modal-overlay">
      <div
        className="modal-container"
        style={{
          maxWidth: "30rem",
          width: "90%",
          maxHeight: "80vh",
          overflow: "hidden", // Prevent overflow on the main container
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">Invite to Tech</h3>
            <button
              onClick={togglePopup}
              type="button"
              className="modal-close-button"
            >
              <IoMdClose size={18} />
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          
          <div style={{ maxHeight: "60vh", overflowY: "auto" }}> {/* Scrollable area */}
            <form onSubmit={handleSubmit} className="modal-form">
              <div className="form-radio-group">
                <label>
                  <input
                    type="radio"
                    value="External"
                    checked={userType === "External"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  External
                </label>
                <label>
                  <input
                    type="radio"
                    value="Internal"
                    checked={userType === "Internal"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  Internal
                </label>
              </div>
  
              {userType === "External" ? (
                <div className="form-group">
                  <label htmlFor="emails" className="form-label">
                    Email addresses (comma-separated)
                  </label>
                  <input
                    type="text"
                    id="emails"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                    className="form-input"
                    placeholder="Enter email addresses"
                    required
                  />
                  {errors.email && <p className="error-text">{errors.email}</p>}
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="internalMember" className="form-label">
                    Select Member
                  </label>
                  <select
                    id="internalMember"
                    className="form-input"
                    value={selectedMemberId}
                    onChange={(e) => setSelectedMemberId(e.target.value)} // Set selected member's ID
                    required
                  >
                    <option value="">Select a member</option>
                    {internalMember.map((member) => (
                      <option key={member.id} value={member.id}>
                        {member.name}
                      </option>
                    ))}
                  </select>
                  {errors.internalMember && <p className="error-text">{errors.internalMember}</p>}
                </div>
              )}
  
              <div className="form-group">
                <label htmlFor="project" className="form-label">
                  Project
                </label>
                <select
                  id="project"
                  className="form-input"
                  value={selectedProjectId}
                  onChange={(e) => setSelectedProjectId(e.target.value)}
                  required
                >
                  <option value="">Select a project</option>
                  {project.length > 0 ? (
                    project.map((p) => (
                      <option key={p._id} value={p._id}>
                        {p.projectname}
                      </option>
                    ))
                  ) : (
                    <option disabled>No projects available</option>
                  )}
                </select>
                {errors.project && <p className="error-text">{errors.project}</p>}
              </div>
  
              <div className="form-group">
                <label htmlFor="team" className="form-label">
                  Team
                </label>
                <select
                  id="team"
                  className="form-input"
                  value={selectedTeamId}
                  onChange={(e) => setSelectedTeamId(e.target.value)}
                  required
                >
                  <option value="">Select a team</option>
                  {teams.length > 0 ? (
                    teams.map((team) => (
                      <option key={team._id} value={team._id}>
                        {team.teamname}
                      </option>
                    ))
                  ) : (
                    <option disabled>No teams available</option>
                  )}
                </select>
                {errors.team && <p className="error-text">{errors.team}</p>}
              </div>
  
              <div className="form-group">
                <label htmlFor="accessLevel" className="form-label">
                  Access Level
                </label>
                <select
                  id="accessLevel"
                  className="form-input"
                  value={accessLevel}
                  onChange={(e) => setAccessLevel(parseInt(e.target.value, 10))}
                >
                  <option value={0}>View</option>
                  <option value={1}>Edit</option>
                </select>
              </div>
              <button
                type="submit"
                className="modal-button w-fit text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-lg px-5 py-2.5"
                disabled={loading}
              >
                {loading ? "Sending Invites..." : "Send Invites"}
              </button>
              {errors.api && <p className="error-text">{errors.api}</p>}
            </form>
          </div>
  
          <div className="toggle-container ">
            <label className="toggle-label">
              <input type="checkbox" className="sr-only peer" />
              <div className="toggle-switch">
                <div className="toggle-handle"></div>
              </div>
            </label>
            <div className="toggle-text">
              Enable invite link and share with your teammates
            </div>
          </div>
  
          <div className="link-container">
            <input
              type="text"
              value={`https://whiteboard.colabrary.com/register/${token}`}
              className="link-input"
              readOnly
            />
            <button className="copy-button" onClick={handleCopyLink}>
              Copy
            </button>
          </div>
        </div>
      </div>
      </div>
    );
  
  
};

export default CreateMembersModal;

