import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './BPMN.css'; // Import your specific CSS styles

const MergedNode = ({ id, data, isConnectable, onNodeChange }) => {
  const [label, setLabel] = useState(data.label || '');
  const [isEditing, setIsEditing] = useState(false);
  const { shape, type } = data;

  // Function to get shape-specific styles
  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return {
          borderRadius: '50%',
          width: '100px',
          height: '60px',
          padding: '10px',
        };
      case 'diamond':
        return {
          width: '80px',
          height: '80px',
          padding: '10px',
          backgroundColor: '#fff',
          transform: 'rotate(45deg)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        };
      case 'rectangle':
      default:
        return {
          borderRadius: '5px',
          width: '120px',
          height: '60px',
          padding: '10px',
        };
    }
  };

  // Function to style handles based on shape and position
  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: 'rotate(-45deg)',
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };

  // Handler for input changes
  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    data.label = newLabel;
    if (onNodeChange) {
      onNodeChange(id, newLabel);
    }
  };

  // Handlers for input focus and blur events
  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={`merged-node ${isEditing ? 'editing' : ''}`}
      style={{
        position: 'relative',
        padding: '0',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        type="text"
        value={label}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={`Enter ${type} text`}
        className="node-input"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
      />
      <Handle
        type="target"
        position="top"
        style={handleStyles('top')}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        style={handleStyles('bottom')}
        isConnectable={isConnectable}
      />
     
    </div>
  );
};

export default MergedNode;
