import moment from 'moment';
import React, { useState,useEffect } from 'react';
import { CgPlayListAdd } from "react-icons/cg";
import { FiMoreVertical, FiEdit, FiTrash, FiUser } from 'react-icons/fi';
import EditTaskModel from './EditTaskModel';
import { useTaskStore } from '../Store/TaskStore/TaskStore';
import AssignUserModal from './AssignUserModal';
import { useAccountStore } from '../Store/AccountStore/AccountStore';
import { useInviteStore } from '../Store/InviteStore/InviteMemberStore';

const Card = ({ item, fetchTasks,members,board,assign }) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { deleteTask,getassignTask } = useTaskStore();
  const {userName}=useAccountStore();
  const [name,setName]=useState('');
  const [assignto, setAssignto] = useState([])

  useEffect(() => {
    const fetchUserName = () => {
      try {
        getassignTask(board._id)
          .then(res => {
            setAssignto(res.data.assignedUsers)
          })
      } catch (error) {
        console.error('Error fetching creator name:', error);
      }
    };


    fetchUserName();
  }, []);

  const findName = (id) => {
    let data = members.find((f) => f.id === id)
    if (data) {
      return data.name
    }
    return name;
  }
  const findUser = (id) => {
    let data = members.find((f) => f.id === id);
    if (data) {
      return data.name;
    }
    return '-';
  }

  const openEditModal = () => {
    setIsEditModalOpen(true);
    setShowDropdown(false);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const handleDelete = async (taskId) => {
    try {
      await deleteTask(taskId);
      fetchTasks(); // Refresh the tasks after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };
  const openAssignModal = () => {
    setIsAssignModalOpen(true);
    setShowDropdown(false); // Close the dropdown when the modal is opened
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
  };
  const toggleDropdown = () => {
    if (!assign) {
      setShowDropdown(!showDropdown);
    }
  };
  useEffect(() => {
    const fetchCreatorName = async () => {
      try {
        const namee = await userName(); // Fetch name based on user ID

        setName(namee.name);
        console.log(namee.name);

      } catch (error) {
        console.error('Error fetching creator name:', error);
      }
    };
    fetchCreatorName();
  }, [userName]);

  console.log(item,board);
  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center w-full">
        <h3 className="card-title">{board.name}</h3>
          <div className="relative">
            {!assign &&(
              <> <FiMoreVertical size={20} className="dropdown-icon" onClick={toggleDropdown} />
              {showDropdown && (
                <div className="card-dropdown">
                  <button className="card-dropdown-button" onClick={openEditModal}>
                    <FiEdit className="mr-2" /> Edit
                  </button>
                  <button className="card-dropdown-button" onClick={() => handleDelete(item._id)}>
                    <FiTrash className="mr-2" /> Delete
                  </button>
                  <button className="card-dropdown-button" onClick={openAssignModal}>
                    <FiUser className="mr-2" /> Assign
                  </button>
                </div>
              )}</>
            )}
           
          </div>
        </div>

      </div>
      <div className="card-body">
      
        <p className="comments font-semibold">{board.subject}</p>
        <p className="comments">{board.description}</p>
        <p className="text-gray-600" style={{ paddingTop: '4px' }}>Created by: {findName(board.user)}</p>
        <span className="text-gray-600 text-sm">
                Assign to: 
                {assignto.map((id, index) => (
                  <span key={index} className='ml-1'>
                    {findUser(id.assignedTo)}
                  </span>
                ))}
              </span>
      </div>

      {isEditModalOpen && (
        <EditTaskModel
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          taskToEdit={item}
          onUpdateTask={fetchTasks}
        />
      )}
      {isAssignModalOpen && (
        <AssignUserModal
          visible={isAssignModalOpen}
          onClose={closeAssignModal}
          taskId={board._id}
        />
      )}
      <div className="card-footer text-right mt-[3rem]">
        <span className="date text-xs text-gray-500">
          {moment(board.createdAt).format('LLL')}
        </span>
      </div>
    </div>
  );
};

const MobileKanbanBoard = (props) => {
  const { tasks, fetchTasks, columns } = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleTasks, setVisibleTasks] = useState({ 'To-Do List': true });
  const { getInvite, invite } = useInviteStore()
  const { members } = useAccountStore()


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const choices = {
    todo: 'To-Do',
    onProgress: 'On-Progress',
    done: 'Done',
    hold: 'Hold'
  }


  const toggleTaskVisibility = (category) => {
    setVisibleTasks((prevVisibleTasks) => ({
      ...prevVisibleTasks,
      [category]: !prevVisibleTasks[category],
    }));
  };

  const fetchInvites = async () => {
    try {
      await getInvite()
    }
    catch (err) {
      console.error('error fetching invites:', err);
    }
  };
  useEffect(() => {
    fetchInvites();
  }, [])


  return (
    <>
      <div className="kanban-board md:hidden">
        {/* Add Task Button */}
        <div className="kanban-board-header" onClick={handleOpenModal}>
          <div className="kanban-board-add-button Reshidden">
            <CgPlayListAdd size={25} className="" />
            <button>Add Task</button>
          </div>
        </div>

        {/* Kanban Board Tasks */}
        <div className="kanban-board-grid">
          {columns.map((column) => (
            <div key={column.id}>
              <div className={`kanban-board-column-header ${column.color}`} onClick={() => toggleTaskVisibility(column.category)}>
                <div className="flex justify-between">
                  <div>
                    <h2 className="font-semibold text-lg">{column.title} ({column.count})</h2>
                    <p className="text-sm">{column.desc}</p>
                  </div>
                  <div>
                    <FiMoreVertical size={20} className="dropdown-icon" />
                  </div>
                </div>
              </div>
              {visibleTasks[column.category] && (
                <div className="kanban-board-column-body">
                 {tasks
                        .filter(task => task.status === choices[column.id])
                        .sort((a, b) => a.index - b.index)  // Sort by index to maintain order
                        .map((item, itemIndex) => (
                          <Card members={members} key={item._id} board={item} fetchTasks={fetchTasks} index={itemIndex} />
                        ))}
                      {invite
                        .filter(task => task.status === choices[column.id])
                        .sort((a, b) => a.index - b.index) // Sort by index to maintain order
                        .map((board, boardIndex) => {
                          // Check if Item and Item._id exist before rendering the Card
                          if (!board || !board._id) {
                            console.error('Item or Item._id is missing:', board);
                            return null; // Skip rendering for undefined or missing _id
                          }

                          return (
                            <Card
                              key={board._id}
                              board={board}
                              members={members}
                              index={boardIndex}
                              assign={true}
                            />
                          );
                        })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileKanbanBoard;
