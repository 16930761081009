import React, {  useCallback, useState } from 'react';
import { useFlowchartStore } from '../../../Store/FlowchartStore/FlowchartStore';
import { FaLink, FaTrash, FaSave } from 'react-icons/fa';
import { FiSquare } from 'react-icons/fi';
import { FaRegCircle } from 'react-icons/fa';
import { GoDiamond } from 'react-icons/go';
import ReactFlow, {
  addEdge,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import ProgramNode from './ProgramNode';
import Swal from 'sweetalert2';
const nodeTypes = {
  program: ProgramNode,
};

const initialElements = [
  { id: '1', type: 'program', data: { label: 'Start', shape: 'oval' }, position: { x: 100, y: 0 } },
  { id: '2', type: 'program', data: { label: 'Process 1', shape: 'rectangle' }, position: { x: 100, y: 150 } },
  { id: '3', type: 'program', data: { label: 'Decision 1', shape: 'diamond' }, position: { x: 100, y: 300 } },
  { id: '4', type: 'program', data: { label: 'Process 2', shape: 'rectangle' }, position: { x: 250, y: 450 } },
  { id: '5', type: 'program', data: { label: 'Process 3', shape: 'rectangle' }, position: { x: -50, y: 450 } },
  { id: '6', type: 'program', data: { label: 'End', shape: 'oval' }, position: { x: 100, y: 600 } },
  { id: 'e1-2', source: '1', target: '2', animated: true },
  { id: 'e2-3', source: '2', target: '3', animated: true },
  { id: 'e3-4', source: '3', target: '4', animated: true, label: 'Yes' },
  { id: 'e3-5', source: '3', target: '5', animated: true, label: 'No' },
  { id: 'e4-6', source: '4', target: '6', animated: true },
  { id: 'e5-6', source: '5', target: '6', animated: true },
];

const ProgramFlowChart = () => {
  const [name,setName]=useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState(initialElements.filter((el) => !el.source && !el.target));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialElements.filter((el) => el.source && el.target));
  const { createChart } = useFlowchartStore();
  const projectId = localStorage.getItem('ProjectId');

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  const saveToAPI = async () => {
    try {
      const flowchart = { nodes, edges };
     await createChart({ flowcharts: flowchart, projectId, type: 'program' ,name: name});
      
      Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
    } catch (error) {
      console.error('Failed to save flowchart to API', error);
      Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
    }
  };

  const addNode = (shape) => {
    const newNode = {
      id: `${new Date().getTime()}`,
      type: 'program',
      data: { label: 'New Node', shape },
      position: { x: 250, y: 250 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
    if (nodes.length < 2) {
      alert('Add at least two nodes to create an edge.');
      return;
    }
    const lastNodeId = nodes[nodes.length - 1].id;
    const secondLastNodeId = nodes[nodes.length - 2].id;

    const newEdge = {
      id: `e${secondLastNodeId}-${lastNodeId}`,
      source: secondLastNodeId,
      target: lastNodeId,
      animated: true,
    };

    setEdges((eds) => addEdge(newEdge, eds));
  };

  const removeSelectedElements = () => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) => !selectedEdges.includes(edge) && !selectedNodeIds.has(edge.source) && !selectedNodeIds.has(edge.target)
    );

    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  return (
    <div style={{ height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <style>
        {`
          .react-flow__attribution {
            display: none;
          }
        `}
      </style>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          nodeTypes={nodeTypes}
          deleteKeyCode={46}
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
      <div className="input-container">
    <input
      type="text"
      placeholder="Enter name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
    />
  </div>

  <div className="responsive">
    <div className="buttons-container">
      <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
        <FiSquare size={20} />
      </button>
      <button onClick={() => addNode('oval')} title="Add Oval Node">
        <FaRegCircle size={20} />
      </button>
      <button onClick={() => addNode('diamond')} title="Add Diamond Node">
        <GoDiamond size={20} />
      </button>
      <button onClick={addEdgeDynamically} title="Add Edge">
        <FaLink size={20} />
      </button>
      <button onClick={removeSelectedElements} title="Remove Selected">
        <FaTrash size={20} />
      </button>
      <button onClick={saveToAPI} title="Save Flowchart">
        <FaSave size={20} />
      </button>
    </div>
  </div>
    </div>
  );
};

export default ProgramFlowChart;
