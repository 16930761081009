import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {CLIENT_ID} from './Config.js'
import './main folder/Fonts/fonts.css'
// import './Main/Fonts/fonts.css'
const root = ReactDOM.createRoot(document.getElementById('root'));


// console.log('Google Client ID:', googleClientId);
root.render(
  <GoogleOAuthProvider clientId={CLIENT_ID}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </GoogleOAuthProvider>
);

reportWebVitals();
