import React, { useState, useCallback } from 'react';
import { useFlowchartStore } from '../../../Store/FlowchartStore/FlowchartStore';
import ReactFlow, {
  addEdge,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'react-flow-renderer';
import { FaLink, FaTrash, FaSave } from 'react-icons/fa';
import FunctionNode from './EPCNode';
import { FiSquare } from 'react-icons/fi'; 
import { FaRegCircle } from "react-icons/fa";
import { GoDiamond } from "react-icons/go";
import Swal from 'sweetalert2';
const nodeTypes = {
 
  function: FunctionNode,
};

const initialElements = [
  { id: '1', type: 'function', data: { label: 'Event 1' }, position: { x: 100, y: 0 } },
  { id: '2', type: 'function', data: { label: 'Function 1' }, position: { x: 100, y: 100 } },
  { id: '3', type: 'function', data: { label: 'Event 2' }, position: { x: 100, y: 200 } },
  { id: 'e1-2', source: '1', target: '2', animated: true },
  { id: 'e2-3', source: '2', target: '3', animated: true },
];

const EPCFlowChart = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialElements.filter(el => !el.source && !el.target));
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialElements.filter(el => el.source && el.target));
  const { createChart} = useFlowchartStore();
  const [selectedShape, setSelectedShape] = useState('rectangle'); 
  const [name,setName]=useState('');

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const projectId=localStorage.getItem('ProjectId');
  const saveToAPI = async () => {
    try {
      const flowchart = { nodes, edges };
       await createChart({ flowcharts: flowchart, projectId,type:'EPC',name:name });
       Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
      } catch (error) {
        console.error('Failed to save flowchart to API', error);
        Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
      }
    };

  const addNode = () => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'function', // Default type
      data: { label: `Node ${nodes.length + 1}`, shape: selectedShape }, // Pass selected shape
      position: { x: 250, y: 250 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
    if (nodes.length < 2) {
      alert('Add at least two nodes to create an edge.');
      return;
    }
    const newEdge = {
      id: `e${nodes.length - 1}-${nodes.length}`,
      source: `${nodes.length - 1}`,
      target: `${nodes.length}`,
      animated: true,
    };
    setEdges((eds) => eds.concat(newEdge));
  };

  const removeSelectedElements = () => {
    
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) =>
        !selectedEdges.includes(edge) &&
        !selectedNodeIds.has(edge.source) &&
        !selectedNodeIds.has(edge.target)
    );
  
    setNodes(updatedNodes);
    setEdges(updatedEdges);
  };

  return (
    <div style={{ height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <style>
        {`
          .react-flow__attribution {
            display: none;
          }
        `}
      </style>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
          nodeTypes={nodeTypes}
          deleteKeyCode={46} // 'delete'-key
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
      <div className="input-container">
      <input
        type="text"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    </div>
  
    <div className="responsive">
      <div className="buttons-container">
        <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
          <FiSquare size={20} />
        </button>
        <button onClick={() => addNode('oval')} title="Add Oval Node">
          <FaRegCircle size={20} />
        </button>
        <button onClick={() => addNode('diamond')} title="Add Diamond Node">
          <GoDiamond size={20} />
        </button>
        <button onClick={addEdgeDynamically} title="Add Edge">
          <FaLink size={20} />
        </button>
        <button onClick={removeSelectedElements} title="Remove Selected">
          <FaTrash size={20} />
        </button>
        <button onClick={saveToAPI} title="Save Flowchart">
          <FaSave size={20} />
        </button>
      </div>
    </div>
    </div>
  );
};

export default EPCFlowChart;
