import { create } from "zustand";
import axios from 'axios';
import { apiUrl } from "../../Config";

export const useInviteStore = create((set) => ({
    currentInvite: null,
    invite: [],
    InviteUser: async (payload) => {
        try {
            console.log(payload);
            const token = localStorage.getItem('token');
            const response = await axios.post(`${apiUrl}/sendInvite` , payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
            console.log('Invite sent successfully', response);
            return response
        } catch (err) {
            console.error('Failed to send invite:', err);
            throw err;
        }
    },

    InviteCreate: async (payload) => {
        try {
            const response = await axios.post(`${apiUrl}/token`, payload);
            console.log('Invite sent successfully', response);
            return response;
        } catch (err) {
            console.error('Failed to send invite:', err);
            throw err;
        }
    },

    getInvite: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`${apiUrl}/getTaskbyID/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('task fetched successfully',response );
            set({ invite: response.data })
        } catch (err) {
            console.log("failed to fetched", err);
            throw err;
        }
    },
    freeSubscribe:async(payload)=>{
        const token=localStorage.getItem('token');
        try{
            const response= await axios.post(`${apiUrl}/free`,payload,{
                headers:{
                    Authorization:`Bearer ${token}`,
                },
            })
            console.log('email send ',response.data);
            return response.data;
        }catch(err){
            console.error('failed to send email',err);
            throw err;
        }
    },

    setCurrentInvite: (invites) => set({ currentInvite: invites })
}));
