import { create } from 'zustand';
import axios from "axios";
import { apiUrl } from '../../Config';

export const useMemberStore = create((set, get) => ({
    currentMember: null,

    getMemberBoard: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/getmember/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('hello member fetched successful:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to fetch boards:', err);
            throw err;
        }
    },
    getMember: async () => {
        const { currentMember } = get();
        if (!currentMember || !currentMember._id) {
            throw new Error("No current board selected");
        }
        const token = localStorage.getItem('token');
  
        try {
            const response = await axios.get(`${apiUrl}/getboard/${currentMember._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (err) {
            console.error('Failed to fetch board:', err);
            throw err;
        }
    },

    getMode: async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/getMode/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log('mode is :', response);
            return response.data;
        } catch (err) {
            console.error('failed to get mode ', err);
            throw err;
        }
    },
createMember:async(payload)=>{
    const token=localStorage.getItem('token');
    try{
        const response=await axios.post(`${apiUrl}/createmember`,payload,{
            headers:{
                Authorization:`${token}`,
            }
        });
        console.log('member created successfully',response.data);
        return response.data;
    }catch(err){
        console.error('failed to update',err);
        throw err;
    }
},
    setCurrentMember: (member) => set({ currentMember: member })
}))