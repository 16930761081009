import React from 'react';
import { Modal, Button, message } from 'antd';
import { useInviteStore } from '../Store/InviteStore/InviteMemberStore';

const FreeAccessPopup = ({  onClose }) => {
  const { freeSubscribe } = useInviteStore();

  const handleButtonClick = async () => {
    try {
      const e= localStorage.getItem('loginEmail')
      await freeSubscribe({ email :e});
      message.success('Free subscription activated!'); // Show success message
      onClose(); // Close the modal after successful API call
    } catch (error) {
      // Handle error
      console.error('Error subscribing for free access:', error);
      message.error('Failed to activate free subscription. Please try again.'); // Show error message
    }
  };

  return (
    <Modal
      title="Free Subscription..."
      open={true}
      onOk={onClose}
      footer={null}
      centered
      // bodyStyle={{ textAlign: 'center', padding: '30px' }}
      style={{ maxWidth: '400px', margin: '0 auto' ,textAlign:'center',padding:'30px'}}
      closable={false}
    >
      <div style={{ marginBottom: '20px' }}>
        <h2 style={{ fontSize: '22px', fontWeight: '600', marginBottom: '15px', color: '#1890ff' }}>
          Thank You! 
        </h2>
        <p style={{ fontSize: '16px', color: '#555' }}>
          You've unlocked 6 months of <strong>FREE Whiteboard access!</strong> Enjoy all the premium features to boost your productivity.
        </p>
        <p style={{ fontSize: '16px', color: '#555', marginTop: '10px' }}>
          Let's get started! 
        </p>
      </div>
      <Button
        type="primary"
        size="large"
        style={{ borderRadius: '5px', padding: '5px 20px' }}
        onClick={handleButtonClick} // Call the function on button click
      >
        OK
      </Button>
    </Modal>
  );
};

export default FreeAccessPopup;
