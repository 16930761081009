import React, { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import './main.css'
import { FiSquare } from 'react-icons/fi';
import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';

import CustomNode from './CustomNode';
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';
import Swal from 'sweetalert2';

const nodeTypes = {
    node: CustomNode,
}


const initialNodes = [
  {
    id: 'root',
    type: 'node',
    position: { x: 600, y: 50 },
    data: { label: 'Company Structure', backgroundColor: '#FFD700'},
    style: { fontWeight:'bold'},
  },

  {
    id: 'management',
    type: 'node',
    position: { x: 300, y: 200 },
    data: { label: 'Management', backgroundColor: '#FF4500' },
   
  },
  {
    id: 'ceo',
    type: 'node',
    position: { x: 100, y: 400 },
    data: { label: 'CEO',backgroundColor: '#FF6347' },
    
  },
  {
    id: 'cto',
    type: 'node',
    position: { x: 300, y: 400 },
    data: { label: 'CTO' ,backgroundColor: '#FF6347'},
    
  },
  {
    id: 'coo',
    type: 'node',
    position: { x: 500, y: 400 },
    data: { label: 'COO' ,backgroundColor: '#FF6347'},
  
  },

  {
    id: 'departments',
    type: 'node',
    position: { x: 900, y: 200 },
    data: { label: 'Departments',backgroundColor: '#4682B4' },
    
  },
  {
    id: 'hr',
    type: 'node',
    position: { x: 700, y: 400 },
    data: { label: 'Human Resources',backgroundColor: '#87CEEB' },
    
  },
  {
    id: 'finance',
    type: 'node',
    position: { x: 900, y: 400 },
    data: { label: 'Finance', backgroundColor: '#1E90FF' },
    
  },
  {
    id: 'engineering',
    type: 'node',
    position: { x: 1100, y: 400 },
    data: { label: 'Engineering',backgroundColor: '#4169E1' },
   
  },
  {
    id: 'frontend',
    type: 'node',
    position: { x: 1000, y: 600 },
    data: { label: 'Frontend Team', backgroundColor: '#20B2AA' },
   
  },
  {
    id: 'backend',
    type: 'node',
    position: { x: 1200, y: 600 },
    data: { label: 'Backend Team',backgroundColor: '#2E8B57' },
    
  },
  {
    id: 'marketing',
    type: 'node',
    position: { x: 1400, y: 400 },
    data: { label: 'Marketing', backgroundColor: '#4412ce' },
    
  },

  {
    id: 'teams',
    type: 'node',
    position: { x: 1300, y: 200 },
    data: { label: 'Teams',backgroundColor: '#DAA520' },
    
  },
  {
    id: 'projectA',
    type: 'node',
    position: { x: 1200, y: 500 }, 
    data: { label: 'Project A Team', backgroundColor: '#6A5ACD' },
   
  },
  {
    id: 'projectB',
    type: 'node',
    position: { x: 1350, y: 500 }, 
    data: { label: 'Project B Team',backgroundColor: '#8A2BE2' },
   
  },
  {
    id: 'support',
    type: 'node',
    position: { x: 1500, y: 500 }, 
    data: { label: 'Support Team',backgroundColor: '#A52A2A' },
     
  },
];


const initialEdges = [
  { id: 'e1', source: 'root', target: 'management', type: 'smoothstep' },
  { id: 'e2', source: 'root', target: 'departments', type: 'smoothstep' },
  { id: 'e3', source: 'root', target: 'teams', type: 'smoothstep' },

  { id: 'e4', source: 'management', target: 'ceo', type: 'smoothstep' },
  { id: 'e5', source: 'management', target: 'cto', type: 'smoothstep' },
  { id: 'e6', source: 'management', target: 'coo', type: 'smoothstep' },

  { id: 'e7', source: 'departments', target: 'hr', type: 'smoothstep' },
  { id: 'e8', source: 'departments', target: 'finance', type: 'smoothstep' },
  { id: 'e9', source: 'departments', target: 'engineering', type: 'smoothstep' },
  { id: 'e10', source: 'departments', target: 'marketing', type: 'smoothstep' },
  { id: 'e11', source: 'engineering', target: 'frontend', type: 'smoothstep' },
  { id: 'e12', source: 'engineering', target: 'backend', type: 'smoothstep' },

  { id: 'e13', source: 'teams', target: 'projectA', type: 'smoothstep' },
  { id: 'e14', source: 'teams', target: 'projectB', type: 'smoothstep' },
  { id: 'e15', source: 'teams', target: 'support', type: 'smoothstep' },
];


const Companymap = () => {
  const {createMap}=useMindmapStore();
  const[name,setName]=useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [selectedShape, setSelectedShape] = useState('rectangle');
    

    const saveMap = async () => {
      try {
          const mindmap = { nodes, edges };
          await createMap({ mindmap: mindmap, name: name });
          Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
      } catch (error) {
          console.error('Failed to save flowchart to API', error);
          Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
      }
  }

     const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const addNode = (shape) => {     
        const newNode = {
            id: `${nodes.length + 1}`,
            type: 'node',
            data: { label: `node ${nodes.length + 1}`, shape: shape || selectedShape,
            backgroundColor:getRandomColor() },
            position: { x: 250, y: 250 },
            style: { backgroundColor:'transparent' },
        };
        setNodes((nds) => nds.concat(newNode));
    };

   const addEdgeDynamically = () => {
        if (nodes.length < 2) {
            alert('Add at least two nodes to create an edge.');
            return;
        }
        const newEdge = {
            id: `e${nodes.length - 1}-${nodes.length}`,
            source: `${nodes.length - 1}`,
            target: `${nodes.length}`,
            
        };
        setEdges((eds) => eds.concat(newEdge));
    };

    const removeSelectedElements = () => {

        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedEdges = edges.filter((edge) => edge.selected);

        if (selectedNodes.length === 0 && selectedEdges.length === 0) {
            alert('Select a node or edge to remove.');
            return;
        }
        const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
        const updatedNodes = nodes.filter((node) => !node.selected);

        const updatedEdges = edges.filter(
            (edge) =>
                !selectedEdges.includes(edge) &&
                !selectedNodeIds.has(edge.source) &&
                !selectedNodeIds.has(edge.target)
        );

        setNodes(updatedNodes);
        setEdges(updatedEdges);
    };

    return (
          <div style={{ height: '100vh', width: '100%' }}>
           <div className='map'> <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >

                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow></div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Company Mindmap"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
            </div>
            <div className="responsive">
                <div className="buttons-container">
                    <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
                        <FiSquare size={20} />
                    </button>
                    <button onClick={() => addNode('oval')} title="Add Oval Node">
                        <FaRegCircle size={20} />
                    </button>
                    <button onClick={() => addNode('diamond')} title="Add Diamond Node">
                        <GoDiamond size={20} />
                    </button>
                    <button onClick={addEdgeDynamically} title="Add Edge">
                        <FaLink size={20} />
                    </button>
                    <button onClick={removeSelectedElements} title="Remove Selected">
                        <FaTrash size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Companymap;
