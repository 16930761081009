import React from "react";
// import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import CreateBoard from "./components/CreateBoard";
import MainLayout from "./components/MainLayout";
import Users from "./components/Users";
import Login from "./components/Login";
import Whiteboard from "./components/Whiteboard";
import Team from "./components/Team";
import ProfilePage from "./components/ProfilePage";
import Projects from "./components/Projects";
import KanbanBoard from "./components/KanbanBoard";
import './App.css'
import Process from "./components/flowchart/process/Process";
import ProgramFlowChart from "./components/flowchart/program/Program";
import SwimlaneDiagram from "./components/flowchart/swimlance/Swimlance";
import SystemFlowDiagram from "./components/flowchart/system/System";
import Workflow from "./components/flowchart/workflow/Workflow";
import EPCFlowChart from "./components/flowchart/EPC/EPC";
import Decision from "./components/flowchart/decision/Decision";
import DataFlowDiagram from "./components/flowchart/dataflow/Dataflow";
import CrossFunctionalFlowChart from "./components/flowchart/crossfuntional/CrossFuntional";
import BPMNFlowChart from "./components/flowchart/BPMN/BPMN";
import Flowchart from "./components/flowchart/Flowchart";
import Register from "./components/Register";
import Otp from "./components/Otp";

import File from "./components/File";
import Board from "./components/Board";
import Healthymap from "./components/mindmap/Healthymap";
import Companymap from "./components/mindmap/Companymap";
import Learningmap from "./components/mindmap/Learningmap";
import Projectmap from "./components/mindmap/Projectmap";
import Personalmap from "./components/mindmap/Personalmap";
import Softwaremap from "./components/mindmap/Softwaremap";
import Mindmap from "./components/mindmap/Mindmap";
// import MindMap from "./components/mindmap/Mindmap";
// import SocketTest from "./socket/SocketTest";


function App() {
  
  return (

    <div>
     
      <Routes>
       
        <Route path="/"  element={<File />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/register/:token?" element={<Register/>}/>
        <Route path="/mainlayout/*" element={<MainLayout />}>
          <Route index element={<CreateBoard />} /> 
          <Route path="members" element={<Users />} />
          <Route path="teams" element={<Team />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="projects" element={<Projects />} />
          <Route path="boards" element={<KanbanBoard />} />
        </Route>
        <Route path="/whiteboard" element={<Whiteboard />} />
        <Route path="/whiteboard/:id" element={<Board />} />

        <Route path="/flowchart/:id" element={<Flowchart/>}/>

        <Route path="/process" element={<Process/>}/>
        <Route path="/program" element={<ProgramFlowChart/>}/>
        <Route path="/swimlance" element={<SwimlaneDiagram/>}/>
        <Route path="/system" element={<SystemFlowDiagram/>}/>
        <Route path="/workflow" element={<Workflow/>}/>
        <Route path="/EPC" element={<EPCFlowChart/>}/>
        <Route path="/decision" element={<Decision/>}/>
        <Route path="/dataflow" element={<DataFlowDiagram/>}/>
        <Route path="/crossfuntional" element={<CrossFunctionalFlowChart/>}/>
        <Route path="/BPMN" element={<BPMNFlowChart/>}/>
      <Route path='/mindmap/:id' element={<Mindmap/>}/> 
      <Route path='/HealthyMindmap' element={<Healthymap/>}/>
      <Route path="/CompanyMindmap" element={<Companymap/>}/>
      <Route path="/LearningMindmap" element={<Learningmap/>}/>
      <Route path="/ProjectMindmap" element={<Projectmap/>}/>
      <Route path="/PersonalMindmap" element={<Personalmap/>}/>
      <Route path="/SoftwareMindmap" element={<Softwaremap/>}/>

      </Routes>  
      {/* <SocketTest/> */}
    </div>
    
  );
}

export default App;

