import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye icons
import { useAccountStore } from "../Store/AccountStore/AccountStore";
import Swal from "sweetalert2"; // Import SweetAlert2

const ChangePasswordPopUp = ({ toggleModal }) => {
  const { passwordChange } = useAccountStore();
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Toggle password visibility
  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = formData;

    // Validate input fields
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    try {
      // Call the password change API
      await passwordChange({
        old_password: currentPassword,
        new_password: newPassword,
      });

      // Show success alert
      Swal.fire({
        title: "Success!",
        text: "Password updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: 'swal2-confirm' // Apply custom class
        },
      });

      toggleModal(); // Close the modal on successful password change
    } catch (err) {
      setError("Failed to update the password. Please try again.");
      Swal.fire({
        title: "Error!",
        text: "Failed to update the password. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between">
          <h2 className="profile-header">Change Password</h2>
          <button
            onClick={toggleModal}
            type="button"
            className="modal-close profile-header"
          >
            <IoMdClose size={25} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-4">
            <div className="profile-section relative">
              <label className="profile-label" htmlFor="currentPassword">
                Current Password
              </label>
              <input
                type={showPasswords.currentPassword ? "text" : "password"}
                name="currentPassword"
                value={formData.currentPassword}
                onChange={handleInputChange}
                className="profile-input"
                placeholder="Enter your current password"
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("currentPassword")}
                className="absolute right-2 top-10"
              >
                {showPasswords.currentPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </button>
            </div>
            <div className="profile-section relative">
              <label className="profile-label" htmlFor="newPassword">
                New Password
              </label>
              <input
                type={showPasswords.newPassword ? "text" : "password"}
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                className="profile-input"
                placeholder="Enter your new password"
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("newPassword")}
                className="absolute right-2 top-10"
              >
                {showPasswords.newPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </button>
            </div>
            <div className="profile-section relative">
              <label className="profile-label" htmlFor="confirmPassword">
                Confirm New Password
              </label>
              <input
                type={showPasswords.confirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="profile-input"
                placeholder="Confirm your new password"
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("confirmPassword")}
                className="absolute right-2 top-10"
              >
                {showPasswords.confirmPassword ? (
                  <AiOutlineEyeInvisible size={20} />
                ) : (
                  <AiOutlineEye size={20} />
                )}
              </button>
            </div>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
          <div className="flex justify-end mt-6">
            <button type="submit" className="btn-primary">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPopUp;
