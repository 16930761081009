import { create } from "zustand";
import axios from "axios";
import { apiUrl } from "../../Config";

export const useAccountStore = create((set) => ({
  currentUser: null, members: [],

  loginUser: async (payload) => {
    try {
      const response = await axios.post(`${apiUrl}/login`, payload);
      console.log(response);

      if (response.status === 200) { // Check if the status is 200 (OK)
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.data); // Assuming the user ID is in response.data.userId

        // You can also update the currentUser in the state
        set({ currentUser: response.data.user });
      }

      return response;
    } catch (err) {
      console.log(err);
    }
  },
  registerUser: async (payload) => {
    try {
      const response = await axios.post(`${apiUrl}/otp`, payload);
      console.log(response);
      return response
    } catch (err) {
      console.log(err);
    }
  },
  otpVerify: async (payload) => {
    try {
      console.log(payload);
      const response = await axios.post(`${apiUrl}/signup`, payload);
      console.log(response);
      return response
    } catch (err) {
      console.log(err);
    }
  },

  googleLogin: async (payload) => {
    const response = await axios.post(`${apiUrl}/verify-token`, payload);
    return response
},

  userProfile: async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${apiUrl}/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;


    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  },
  passwordChange: async (payload) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(`${apiUrl}/change-password`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('password updated successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to update', err);
      throw err;
    }
  },
  subUser: async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
       `${apiUrl}/sub-user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  },
  setSubUser: async (payload) => {
    set({ members: payload })

  },
  userName: async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(
        ` ${apiUrl}/sub/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('user fetched successfully', response);
      return response.data;
    } catch (err) {
      console.error('failed to fetch user:', err);
      throw err;
    }
  }

}));
