import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountStore } from "../Store/AccountStore/AccountStore";
import { GoogleLogin } from "@react-oauth/google";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; 
import { CLIENT_ID } from "../Config";
import ChangePasswordPopUp from "./ChangePasswordPopUp";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loginUser, googleLogin } = useAccountStore();

  // Validation function for email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const togglePopup = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal open state:", !isModalOpen);
  };
  // Handle standard login form submission
  const handleLogin = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    // Input validation checks
    if (!email) {
      setError("Email is required.");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if (!password) {
      setError("Password is required.");
      return;
    }

    try {
      localStorage.setItem("loginEmail", email);
      console.log(email, password);
      const result = await loginUser({ email, password });
console.log(result.status);
      if (result && result.status === 200) {
        navigate("/mainlayout");
      } else {
        // Handle specific cases for incorrect email or password
        if (result.data?.error === "password_incorrect") {
          setError("Password is incorrect. Please try again.");
        } else if (result.data?.error === "email_incorrect") {
          setError("Email is incorrect. Please try again.");
        } else {
          setError("Login failed. Please check your credentials and try again.");
        }
      }
    } catch (error) {
      // Handle errors from the backend
      console.error("Error:", error);
      setError(
        error.response?.data?.error ||
        "An error occurred. Please try again later."
      );
    }
  };


  // Handle Google login failure
  const onFailure = (error) => {
    setError("Error! Something went wrong with Google login.");
  };

  // Handle Google login success
  const handleLoginSuccess = async (response) => {
    const token = response.credential;
    console.log(response)
    console.log({ token })
    setError(""); // Reset error message

    try {
      
      googleLogin({ auth_token: token })
      .then(res => {
        if (res && res.status === 200) { // Check if the response is OK
          console.log(res.data.data);
          localStorage.setItem('token', res.data.data.token);
          localStorage.setItem('userId',res.data.data.id);
          localStorage.setItem('loginEmail', res.data.data.email);
          navigate('/mainlayout');
        }
      })
      .catch(err => {
        console.error('Login failed', err);

      });
    

    } catch (error) {
      // Handle errors during Google login
      console.error("Google login error:", error);
      setError(
        error.response?.data?.error ||
        "An error occurred during Google authentication."
      );
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 px-4">
      <div className="p-8 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl">
        <div className="flex justify-center items-center">
          <img src="/Whiteboard Logo PNG Black.png" className="w-[12rem] mb-9" />
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleLogin} className="space-y-4">
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Email address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="your@email.com"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="space-y-2 relative">
            <label className="block text-xl font-medium text-gray-700">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"} // Toggle input type
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="••••••••"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {/* Eye icon to toggle password visibility */}
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
            >
              {showPassword ? (
                <AiFillEyeInvisible className="text-gray-500" style={{ marginTop: '22px' }} />
              ) : (
                <AiFillEye className="text-gray-500" style={{ marginTop: '22px' }} />
              )}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-[#5845ee] text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Login to dashboard
          </button>
          <div className="flex justify-between">  
            <GoogleLogin clientId={CLIENT_ID} onSuccess={handleLoginSuccess} onError={onFailure} useOneTaps />

            {/* <button onClick={togglePopup}
              className="text-blue-500 ml-1"
            >Forgot password?</button> */}
            </div>
        </form>
        <p className="w-full mt-2 text-gray-600 py-2 px-4 flex items-center justify-center">
          Don't have an account?{" "}
          <button
            onClick={() => navigate("/register")}
            className="text-blue-500 ml-1"
          >
            Register here.
          </button>
        </p>
      </div>
      {isModalOpen && <ChangePasswordPopUp toggleModal={togglePopup} />}
    </div>
  );
};

export default Login;
