import React, { useRef, useState, useEffect } from "react";
import * as fabric from 'fabric';
import { useNavigate, useParams } from 'react-router-dom';
import { ImPencil2 } from "react-icons/im";
import { FaHighlighter } from "react-icons/fa";
import { LuEraser } from "react-icons/lu";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaRegCircle } from "react-icons/fa";
import { MdOutlineRectangle } from "react-icons/md";
import { IoTriangleOutline } from "react-icons/io5";
import { PiLineVertical } from "react-icons/pi";
import { BiPolygon } from "react-icons/bi";
import { PiTextbox } from "react-icons/pi";
import { SlActionUndo } from "react-icons/sl";
import { IoArrowRedoOutline } from "react-icons/io5";
import './Canvas.css';
import { IoMdAdd } from 'react-icons/io';
import CreateMembersModal from './CreateMemberModal';
import { useBoardStore } from "../Store/BoardStore/BoardStore";
import { useProjectStore } from "../Store/ProjectStore/ProjectStore";


export default function Whiteboard(props) {
  const { access } = props;
  const { id } = useParams();
  const [name, setName] = useState('');
  const [color, setColor] = useState("#3B3B3B");
  const [size, setSize] = useState(3);
  const [cursor, setCursor] = useState("default");
  const [mode, setMode] = useState('');
  const [activeButton, setActiveButton] = useState('');
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [boards, setBoards] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pname, setPName] = useState('');
  const [canvasHistory, setCanvasHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const { getBoardId, setCurrentBoard, getProjectbyId, updateBoard, updateBoardname, addshapes, updateProject, undoDrawing, redoDrawing } = useBoardStore();
  const [selected, setSelected] = useState("")
  const { getProject, project } = useProjectStore();
  const [isUndoActive, setUndoActive] = useState(false);
  const [isRedoActive, setRedoActive] = useState(false);


  //whiteboard ki id k behalf pe data fetch wali api
  const fetchBoard = async () => {
    try {
      const response = await getBoardId();
      console.log('Board response:', response);

      const ID = response.projectId
      if (response) {
        setBoards(response);
        setName(response.name);
        setSelected(ID);
        if (response.shapes) {
          setCanvasHistory([response.shapes]);
          setHistoryIndex(0);
        }
      } else {
        console.error('Board response is null or undefined.');
      }
    } catch (error) {
      console.error('Error fetching board:', error);
    }
  };
  //set kiya hai current board ki id ko
  useEffect(() => {
    if (id) {
      setCurrentBoard({ _id: id });
      fetchBoard();
    }
  }, [id, setCurrentBoard]);

  //all project fetch wali api
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        await getProject();
      } catch (err) {
        console.error('Failed to fetch projects:', err);
      }
    };

    fetchProjects();
  }, []);
  //jo project board m selected hai usko fetch krne  wali api
  useEffect(() => {
    const fetchName = async () => {
      try {
        const Name = await getProjectbyId();
        console.log(Name)
        setPName(Name.projectname);
      } catch (err) {
        console.error('failed to fetch project name', err);
      }
    };
    fetchName()
  }, []);

  //select project
  const handleSelectChange = (event) => {
    if (event && event.target) {
      const selectedProjectId = event.target.value;

      const payload = { projectId: selectedProjectId };

      setSelected(selectedProjectId);

      // Log the payload to verify its structure before making the request
      console.log("Payload for updateProject:", payload);

      // Call updateProject with the correctly structured payload
      updateProject(payload)
        .then(response => {
          console.log("Updated Project:", response);
        })
        .catch(error => {
          console.error("Failed to update project:", error);
        });
    } else {
      console.error("Event target is undefined:", event);
    }
  };
  //update wali api
  const updateShapes = async () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;

    if (!canvas) return;

    const shapes = canvas.toJSON().objects;

    if (!Array.isArray(shapes)) {
      console.error("Shapes is not an array:", shapes);
      return;
    }

    const newHistory = [...canvasHistory.slice(0, historyIndex + 1), shapes];
    setCanvasHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
    setUndoActive(true);
    setRedoActive(false);
    try {
      await updateBoard({ shapes: shapes });

    } catch (error) {
      console.error("Error updating board:", error);
    }
  };
  //update name wali api
  const updateDrawingName = async () => {
    if (!name || typeof name !== 'string') {
      console.error("Invalid name");
      return;
    }
    try {
      await updateBoardname({ name });
    } catch (error) {
      console.error("Error updating drawing name:", error);
    }
  };
  //name change krne ka button
  const handleBlur = () => {
    updateDrawingName(name);
    // updateShapes(name);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    } else {
      const canvas = canvasRef.current;
      if (boards.shapes) {
        redraw(boards.shapes);
      }
      // const ctx = canvas.getContext("2d");
      // canvas.width = window.innerWidth;
      // canvas.height = window.innerHeight;
    }
  }, [navigate, boards]);


  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);
    canvas.setWidth(window.innerWidth);
    canvas.setHeight(window.innerHeight);
    canvasRef.current.__fabricCanvas = canvas;

    canvas.on('object:modified', updateShapes);
    canvas.on('path:created', updateShapes);

    canvas.on('selection:created', function (e) {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        console.log('Object selected:', activeObject);
      }
    });

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.remove(activeObject);
          canvas.discardActiveObject();
          canvas.renderAll();
          updateShapes();
        }
      }
    });

    return () => {
      if (canvasRef.current && canvasRef.current.__fabricCanvas) {
        canvasRef.current.__fabricCanvas.dispose();
      }
    };
  }, []);

  //whiteboard m shapes add krne ka logic
  const saveCanvasState = async () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      const canvasState = canvas.toJSON();
      const shapesArray = canvasState.objects;
      try {
        await addshapes({ shapes: shapesArray });
      } catch (error) {
        console.error('Error saving canvas state:', error);
      }
    }
  };

  //sari shapes ka switch case
  const addShape = (shapeType) => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      let shape;
      const options = {
        left: Math.random() * 700,
        top: Math.random() * 500,
        fill: 'transparent',
        stroke: 'black',
        strokeWidth: 2,
      };

      switch (shapeType) {
        case 'circle':
          shape = new fabric.Circle({ ...options, radius: 50 });
          break;
        case 'rectangle':
          shape = new fabric.Rect({ ...options, width: 100, height: 100 });
          break;
        case 'triangle':
          shape = new fabric.Triangle({ ...options, width: 100, height: 100 });
          break;
        case 'line':
          shape = new fabric.Line([50, 50, 200, 200], { stroke: 'black', strokeWidth: 2 });
          break;
        case 'ellipse':
          shape = new fabric.Ellipse({ ...options, rx: 70, ry: 50 });
          break;
        case 'path':
          shape = new fabric.Path('M 0 0 L 100 100 L 200 0 Z', { stroke: 'black', fill: 'transparent', strokeWidth: 2 });
          break;
        case 'polygon':
          shape = new fabric.Polygon([{ x: 0, y: 0 }, { x: 100, y: 100 }, { x: 100, y: 0 }], { stroke: 'black', fill: 'transparent', strokeWidth: 2 });
          break;
        case 'group':
          const circle = new fabric.Circle({ ...options, radius: 30 });
          const rect = new fabric.Rect({ ...options, left: 70, top: 70, width: 60, height: 60 });
          shape = new fabric.Group([circle, rect], { left: 150, top: 150 });
          break;
        case 'textbox':
          shape = new fabric.Textbox('Type here', {
            left: Math.random() * 700,
            top: Math.random() * 500,
            fontSize: 20,
            width: 150,
            fill: 'black',
            borderColor: 'black',
            editable: true,
            backgroundColor: 'lightgrey',
          });
          break;
        default:
          return;
      }
      canvas.add(shape);
      canvas.renderAll();
      // updateShapes();
      setMode('shapes');
      saveCanvasState();
    }
  };
  //select the pen , pencile and highligher logic
  const setupDrawingMode = () => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      if (mode === 'erase' || mode === 'pencil' || mode === 'highlighter') {
        canvas.isDrawingMode = true;
        if (!canvas.freeDrawingBrush) {
          canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        }
        canvas.freeDrawingBrush.color = color;
        canvas.freeDrawingBrush.width = size;
        canvas.freeDrawingBrush.opacity = mode === 'highlighter' ? 0.5 : 1;
      } else {
        canvas.isDrawingMode = false;
      }
    }
  };

  useEffect(() => {
    setupDrawingMode();
  }, [mode, color, size]);

  //whitebaord ka sara data delete krne k liye
  const clearCanvas = () => {
    setBoards([]);
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas;
    if (canvas) {
      canvas.clear();
      canvas.renderAll();
      updateShapes()
    }
  };
  const redraw = (savedShapes) => {
    const canvas = canvasRef.current && canvasRef.current.__fabricCanvas; // Reference to the canvas object

    if (canvas) {
      // Clear the canvas (removes all current objects)
      canvas.clear();

      if (savedShapes) {
        // Load new objects or shapes if provided (e.g., from saved state or API)
        canvas.loadFromJSON({ objects: savedShapes }, () => {
          // This callback ensures the canvas is rendered after loading
          canvas.renderAll();
          canvas.requestRenderAll(); // Re-render all objects on the canvas
        });
      } else {
        // If no new shapes are provided, just render the canvas with no objects
        canvas.renderAll();
        canvas.requestRenderAll();// Ensure canvas is fully re-rendered
      }
    }
  };

  useEffect(() => {
    if (boards.shapes) {
      redraw(boards.shapes);
    }
  }, [boards.shapes]);
  //reload pe array empty 


  //undo api
  const undo = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    console.log('Undo triggered');
    try {
      const result = await undoDrawing();
      if (result && result.shapes) {
        redraw(result.shapes);
        console.log("Undo action performed");

        // Activate the redo button since undo was performed
        setRedoActive(true);

        // Deactivate the undo button if no more actions to undo
        if (!result.hasMoreUndo) {
          setUndoActive(false);
        }
      }

      getBoardId();
    } catch (error) {
      console.error('Error during undo:', error);
    }
  };
  //redo api
  const redo = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    console.log('Redo triggered');
    try {
      const result = await redoDrawing();
      if (result && result.shapes) {
        redraw(result.shapes);
        console.log("Redo action performed");

        // Deactivate the redo button if no more actions to redo
        if (!result.hasMoreRedo) {
          setRedoActive(false);
        }

        // Re-activate the undo button since a redo was performed
        setUndoActive(true);
      }

      getBoardId();
    } catch (error) {
      console.error('Error during redo:', error);
    }
  };


  const getPen = () => {
    setCursor("default");
    setSize(3);
    setColor("#3B3B3B");
    setMode('pencil');
    setActiveButton('pencil');

  };

  const eraseCanvas = () => {
    setCursor("grab");
    setSize(20);
    setColor("#F0F0F0");
    setMode('erase');
    setActiveButton('erase');
  };

  const activateHighlighter = () => {
    setCursor("crosshair");
    setSize(10);
    setColor("#FFFF00");
    setMode('highlighter');
    setActiveButton('highlighter');

  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleShapeClick = (shapeType) => {
    setActiveButton(shapeType);
    addShape(shapeType);
  };

  const handleActionClick = (actionType) => {
    setActiveButton(actionType);
    if (actionType === 'clear') {
      clearCanvas();
    } else if (actionType === 'undo') {
      undo();
    } else if (actionType === 'redo') {
      redo();
    }
  };
  return (<>


    <div className="flex sticky top-0 z-50 justify-between sm:flex-wrap sm:gap-1 xs:gap-1 md:flex-wrap items-center bg-gray-100 p-2 rounded-lg whitespace-nowrap	">
      <div className="flex gap-2 md:flex-row items-center md:justify-between justify-center">

        {/* Whiteboard Title */}
        <div className="font-bold text-black text-lg md:text-xl mb-2 md:mb-0 md:mr-4 hidden md:block">
          <span>Whiteboard</span>
        </div>

        {/* Input Field */}
        <div className="text-black font-sans rounded w-full md:w-auto sm:w-[90%] xs:w-[90%]">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleBlur}
            readOnly={!access}
            className="border-none bg-blue-400 text-white text-lg md:text-xl outline-none p-[0.2rem] rounded w-full sm:w-20 md:w-auto inline-block whitespace-nowrap" // Adjusted width for mobile
          />
        </div>
      </div>

      {/* Select and Invite Members Section */}
      <div className="flex flex-row md:flex-row items-center gap-2 mt-2 xs:justify-between md:justify-center whitespace-nowrap	">

        {/* Select Element */}
        <div className="w-[10rem]">
          <select
            id="project-select" 
            value={selected}
            onChange={handleSelectChange}
            disabled={!access}
            className="p-[0.4rem] rounded w-[100%] text-sm md:text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out"
          >
            <option value="" disabled>
              {pname ? pname : 'Select a project'}
            </option>
            {project.map((p) => (
              <option key={p._id} value={p._id}>
                {p.projectname}
              </option>
            ))}
          </select>
        </div>

        {/* Invite Members Button */}
        <div
          onClick={togglePopup}
          className="flex items-center justify-center bg-blue-600 text-white p-1 rounded cursor-pointer transition duration-200 h-9 w-auto md:w-auto" // Adjusted padding and height
        >
          <IoMdAdd size={20} />
          <span className="hidden md:inline font-medium text-xs md:text-sm">Invite Members</span> {/* Hidden on mobile */}
        </div>
      </div>



    </div>
    {access ? isPopupOpen && <CreateMembersModal togglePopup={togglePopup} /> : null}

    <div className="canvas">
      {access &&
        <div className="canvas-btn flex flex-wrap z-50 justify-between">
          <button
            onClick={getPen}
            className={`btn-width ${activeButton === 'pencil' ? 'active' : ''} flex items-center`}
          >
            <ImPencil2 />
            <span className="tooltip">Pencil</span>
          </button>
          <button
            onClick={activateHighlighter}
            className={`btn-width ${activeButton === 'highlighter' ? 'active' : ''} flex items-center`}
          >
            <FaHighlighter />
            <span className="tooltip">Highlighter</span>
          </button>
          <button
            onClick={eraseCanvas}
            className={`btn-width ${activeButton === 'erase' ? 'active' : ''} flex items-center`}
          >
            <LuEraser />
            <span className="tooltip">Eraser</span>
          </button>
          <div className="btn-width flex items-center">
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <span className="tooltip">Color Picker</span>
          </div>
          <div className="btn-width flex items-center">
            <select
              className="btn-width"
              value={size}
              onChange={(e) => setSize(parseInt(e.target.value))}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span className="tooltip">Brush Size</span>
          </div>

          <button
            onClick={() => handleShapeClick('circle')}
            className={`btn-width flex items-center ${activeButton === 'circle' ? 'active' : ''}`}
          >
            <FaRegCircle />
            <span className="tooltip">Circle</span>
          </button>
          <button
            onClick={() => handleShapeClick('rectangle')}
            className={`btn-width flex items-center ${activeButton === 'rectangle' ? 'active' : ''}`}
          >
            <MdOutlineRectangle />
            <span className="tooltip">Rectangle</span>
          </button>
          <button
            onClick={() => handleShapeClick('triangle')}
            className={`btn-width flex items-center ${activeButton === 'triangle' ? 'active' : ''}`}
          >
            <IoTriangleOutline />
            <span className="tooltip">Triangle</span>
          </button>
          <button
            onClick={() => handleShapeClick('line')}
            className={`btn-width flex items-center ${activeButton === 'line' ? 'active' : ''}`}
          >
            <PiLineVertical />
            <span className="tooltip">Line</span>
          </button>
          <button
            onClick={() => handleShapeClick('polygon')}
            className={`btn-width flex items-center ${activeButton === 'polygon' ? 'active' : ''}`}
          >
            <BiPolygon />
            <span className="tooltip">Polygon</span>
          </button>
          <button
            onClick={() => handleShapeClick('path')}
            className={`btn-width flex items-center ${activeButton === 'path' ? 'active' : ''}`}
          >
            <IoTriangleOutline />
            <span className="tooltip">Path</span>
          </button>
          <button
            onClick={() => handleShapeClick('textbox')}
            className={`btn-width flex items-center ${activeButton === 'textbox' ? 'active' : ''}`}
          >
            <PiTextbox />
            <span className="tooltip">Text Box</span>
          </button>
          <button
            onClick={() => handleActionClick('clear')}
            className={`btn-width flex items-center ${activeButton === 'clear' ? 'active' : ''}`}
          >
            <RiDeleteBin5Fill />
            <span className="tooltip">Clear All</span>
          </button>
          {/* undo redo button */}
          <button
            onClick={() => handleActionClick('undo')}
            disabled={!isUndoActive}
            className={`btn-width flex items-center ${activeButton === 'undo' ? 'active' : ''}`}
          ><span className="tooltip">undo</span>
            <SlActionUndo />
          </button>
          <button
            onClick={() => handleActionClick('redo')}
            disabled={!isRedoActive}
            className={`btn-width flex items-center ${activeButton === 'redo' ? 'active' : ''}`}
          ><span className="tooltip">redo</span>
            <IoArrowRedoOutline />
          </button>

        </div>
      }
      <div style={{ width: '100%', overflow: 'auto' }}>
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{

            cursor: cursor,
            pointerEvents: access ? 'auto' : 'none'
          }}
        />
      </div>
    </div>

  </>
  );

}
