import React, { useState, useEffect, useRef } from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { useBoardStore } from '../Store/BoardStore/BoardStore';
import FreeAccessPopup from './FreeAccessPopup'; // Import the popup modal component

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State to control popup visibility
  const searchBarRef = useRef(null);
  const { searchBoard } = useBoardStore();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setIsSearchActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      setError('Please enter a search term');
      setSearchResults([]);
      return;
    }

    try {
      const data = await searchBoard(searchTerm); // Call the searchBoard function with the searchTerm

      if (data.length === 0) {
        setError('Name does not exist');
        setSearchResults([]);
      } else {
        setError('');
        setSearchResults(data);
      }
    } catch (error) {
      console.error('Failed to search drawings:', error);
      setError('Failed to search drawings');
      setSearchResults([]);
    }
  };

  const handleUpgradeClick = () => {
    setIsPopupVisible(true); // Show the popup when "Free to Upgrade" button is clicked
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Close the popup
  };

  return (
    <div className="search-bar" ref={searchBarRef}>
      <div className="search-input">
        <IoSearch size={25} />
        <input
          type="text"
          placeholder="Search boards"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => setIsSearchActive(true)}  // Show search results on click
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
      </div>
      <div className="action-buttons">
        <button className="upgrade-button" onClick={handleUpgradeClick}>
          Free to Upgrade
        </button>
        <IoMdNotificationsOutline size={30} className="" />
      </div>

      {/* Conditionally render search results */}
      {isSearchActive && (
        <div className='searchCard'>
          <div className="search-results" style={{ fontSize: '16px', fontWeight: 500, paddingBottom: '8px', borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
            Found Results
          </div>
          {error && (
            <div className="error-message" style={{ color: 'red' }}>
              {error}
            </div>
          )}
          {searchResults.length > 0 && !error && (
            <div className="search-results p-2">
              {searchResults.map(result => (
                <div key={result._id} className="search-result-item">
                  <p>{result.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Show FreeAccessPopup when triggered */}
      {isPopupVisible && (
        <FreeAccessPopup onClose={closePopup} />
      )}
    </div>
  );
};

export default SearchBar;




 {/* <FaRegCircleQuestion size={24} className="" /> */}
  {/* <div className='border bg-blue-400 rounded-full w-12 h-12 flex justify-center items-center'>
          <span className='text-white'>
            D
          </span>
        </div> */}