import React, { useState } from "react";
import { CiSearch, CiSquarePlus } from "react-icons/ci";
import { IoMdNotificationsOutline } from "react-icons/io";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CreateProjectModal from "./CreateProjectModal";
import AddTaskModal from "./AddTaskModal";
import CreateTeamModal from "./CreateTeamModal";
import { IoSearch } from "react-icons/io5";
import CreateMembersModal from "./CreateMemberModal";

const MobileSaibar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
 

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
 

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickSearchIcon = () => {
    setShowSearch(!showSearch);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenCreateTeamModal = () => {
    setIsCreateTeamModalOpen(true);
  };

  const handleCloseCreateTeamModal = () => {
    setIsCreateTeamModalOpen(false);
  };

  const handleOpenProjectModal = () => {
    setIsCreateProjectOpen(true);
  };

  const handleCloseProjectModal = () => {
    setIsCreateProjectOpen(false);
  };

  const location = useLocation();
  const pathname = location.pathname;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const modifiedPathname = capitalizeFirstLetter(pathname.slice(12));

  return (
    <div className="w-full flex justify-center items-center md:hidden h-[65px]">
      <button className="md:hidden  m-2 rounded bg-gray-100 w-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-2">
          <img
          src="/LOGO ICON.png" width='50'
          className=" flex justify-center text-black items-center ml-2  "
          alt="Whiteboard Logo"
        />
            <div className="relative inline-block text-left mt-[0.5rem]">
              <div onClick={toggleDropdown} className="cursor-pointer">
                <CiSquarePlus size={25} color="#3b82f6" />
              </div>

              {isOpen && (
                <div className="absolute right-0 left-0 w-56 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                  <div className="p-2">
                    <div
                      className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
                      onClick={handleOpenModal}
                    >
                      <CiSquarePlus size={25} color="#3b82f6" />
                      <span className="ml-2">Add Task</span>
                    </div>
                    <div
                      className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
                      onClick={handleOpenCreateTeamModal}
                    >
                      <CiSquarePlus size={25} color="#3b82f6" />
                      <span className="ml-2">Create Team</span>
                    </div>
                    <div className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded">
                      <CiSquarePlus size={25} color="#3b82f6" />
                      <span onClick={togglePopup} className="ml-2">
                        Invite Member
                      </span>
                    </div>
                    <div
                      className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
                      onClick={handleOpenProjectModal}
                    >
                      <CiSquarePlus size={25} color="#3b82f6" />
                      <span className="ml-2">Create Project</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h1 className="header-title">{modifiedPathname}</h1>
          <div className="flex gap-2 justify-center items-center">
            <div className="relative">
              <button onClick={handleClickSearchIcon}>
                <CiSearch size={22} className="mt-[0.5rem]" />
              </button>
              {showSearch && (
                <div className="absolute z-50 top-2 right-0">
                  <div
                    className="flex justify-start items-center h-[2.3rem] border bg-white rounded"
                    onClick={() => setShowSearch(false)}
                  >
                    <IoSearch size={24} className="p-1" />
                    <input
                      type="text"
                      placeholder="Search boards"
                      className="outline-none w-[207px]"
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              <IoMdNotificationsOutline size={22}  />
            </div>
          </div>
        </div>
      </button>
      <AddTaskModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onAddTask={(newTask) => console.log("New Task:", newTask)}
      />
      <CreateTeamModal
        isOpen={isCreateTeamModalOpen}
        onClose={handleCloseCreateTeamModal}
      />
      {isPopupOpen && <CreateMembersModal togglePopup={togglePopup} />}
      <CreateProjectModal
        isOpen={isCreateProjectOpen}
        onClose={handleCloseProjectModal}
     
      />
    </div>
  );
};

export default MobileSaibar;