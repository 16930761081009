import React, { useEffect, useState } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  Controls,
  addEdge
} from 'react-flow-renderer';
import './main.css'
import CustomNode from './CustomNode';
import { FaLink, FaTrash, FaSave } from 'react-icons/fa';
import { FiSquare } from 'react-icons/fi';
import { FaRegCircle } from "react-icons/fa";
import { GoDiamond } from "react-icons/go";
import Swal from 'sweetalert2'
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';

const nodeType = {
  node: CustomNode
}

const Mindmap = () => {
  const { getMapId, updateMap } = useMindmapStore();
  const [elements, setElements] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [name, setName] = useState('');

  const fetchMindmap = async () => {
    try {
      const data = await getMapId();
      setName(data.name || 'Mindmap');
      const mindmap = data.mindmap[0];
      setNodes(mindmap.nodes);;
      setEdges(mindmap.edges);
      if (data && data.mindmap && data.mindmap.length > 0) {
        const nodes = mindmap.nodes.map((node) => ({
          id: node.id,
          type: node.type,
          data: {
            label: node.data.label,
            shape: node.data.shape,
          },
          position: node.position,
        }));
        const edges = mindmap.edges.map((edge) => ({
          id: edge.id,
          source: edge.source,
          target: edge.target
        }));
        setElements([...nodes, ...edges]);
      } else {
        console.warn('no mindmap data available');
      }
    } catch (error) {
      console.error('error fetching mindmap', error);
      setElements([]);
    }
  };

  useEffect(() => {
    fetchMindmap();
  }, [])
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));
  const saveToAPI = async () => {
    try {
      const projectId = localStorage.getItem('ProjectId');
      const mindmap = { nodes, edges };
      await updateMap({ mindmap: mindmap, projectId, name: name });
      console.log('mindmap updated successfully.');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'mindmap updated successfully!',
      });
      fetchMindmap();
    } catch (error) {
      console.error('Failed to save mindmap to API', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to save mindmap to API!',
      });
    }
  };
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const addNode = (shape) => {
    const newNode = {
      id: `${nodes.length + 1}`,
      type: 'node', // Ensure this matches 'program' in nodeTypesMap
      data: {
        label: `Node ${nodes.length + 1}`,
        shape, backgroundColor: getRandomColor()
      },
      position: { x: 250, y: 250 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const addEdgeDynamically = () => {
    if (nodes.length < 2) {
      alert('Add at least two nodes to create an edge.');
      return;
    }
    
    const sourceNode = nodes[nodes.length - 2].id; // Second last node
    const targetNode = nodes[nodes.length - 1].id; // Last node
  
    const newEdge = {
      id: `e${sourceNode}-${targetNode}`,
      source: sourceNode,
      target: targetNode,
     
    };
  
    setEdges((eds) => [...eds, newEdge]);
  };
  
  

  const removeSelectedElements = () => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);

    if (selectedNodes.length === 0 && selectedEdges.length === 0) {
      alert('Select a node or edge to remove.');
      return;
    }
    const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
    const updatedNodes = nodes.filter((node) => !node.selected);

    const updatedEdges = edges.filter(
      (edge) =>
        !selectedEdges.includes(edge) &&
        !selectedNodeIds.has(edge.source) &&
        !selectedNodeIds.has(edge.target)
    );

    setNodes(updatedNodes);
    setEdges(updatedEdges);
   
  };
  return (
    <div style={{ height: '100vh', width: '100%' }}>

      <div className="input-container">
        <input
          type="text"
          placeholder="Mindmap Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      {elements.length > 0 ? (
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeType}
            edges={edges}
            onConnect={onConnect}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          >
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>
      ) : (
        <p>No mindmap data available</p>
      )}
      <div className="responsive">
        <div className="buttons-container">
          <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
            <FiSquare size={20} />
          </button>
          <button onClick={() => addNode('oval')} title="Add Oval Node">
            <FaRegCircle size={20} />
          </button>
          <button onClick={() => addNode('diamond')} title="Add Diamond Node">
            <GoDiamond size={20} />
          </button>
          <button onClick={addEdgeDynamically} title="Add Edge">
            <FaLink size={20} />
          </button>
          <button onClick={removeSelectedElements} title="Remove Selected">
            <FaTrash size={20} />
          </button>
          <button onClick={saveToAPI} title="Save Flowchart">
            <FaSave size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};



export default Mindmap
