import React, { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import './main.css'
import { FiSquare } from 'react-icons/fi';
import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';
import CustomNode from './CustomNode';
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';
import Swal from 'sweetalert2';

const nodeTypes = {
    node: CustomNode,
}


const initialNodes = [
    {
        id: 'root',
        type: 'node',
        data: { label: 'Project Planning', backgroundColor: '#FF6347' },
        position: { x: 400, y: 20 }, style: { fontWeight: 'bold' }
    },

    {
        id: 'initiation',
        type: 'node',
        data: { label: 'Initiation', backgroundColor: '#4682B4' },
        position: { x: 100, y: 100 },
    },
    {
        id: 'goal',
        type: 'node',
        data: { label: 'Define Goal', backgroundColor: '#FFD700' },
        position: { x: 100, y: 200 },
    },
    {
        id: 'feasibility',
        type: 'node',
        data: { label: 'Feasibility Study', backgroundColor: '#ADFF2F' },
        position: { x: 250, y: 200 },
    },
    { id: 'stakeholders', type: 'node', data: { label: 'Identify Stakeholders' }, position: { x: 400, y: 200 }, style: { backgroundColor: '#FF69B4', color: '#fff', border: '1px solid #FF1493' } },

    { id: 'planning', type: 'node', data: { label: 'Planning' }, position: { x: 650, y: 100 }, style: { backgroundColor: '#8A2BE2', color: '#fff', border: '1px solid #6A0DAD' } },
    { id: 'resources', type: 'node', data: { label: 'Resources Allocation' }, position: { x: 650, y: 200 }, style: { backgroundColor: '#DC143C', color: '#fff', border: '1px solid #B22222' } },
    {
        id: 'schedule',
        type: 'node',
        data: { label: 'Schedule Development', backgroundColor: '#32CD32' },
        position: { x: 800, y: 200 },
    },
    {
        id: 'risks',
        type: 'node',
        data: { label: 'Risk Assessment', backgroundColor: '#FF8C00' },
        position: { x: 950, y: 200 },
    },

    {
        id: 'execution',
        type: 'node',
        data: { label: 'Execution', backgroundColor: '#20B2AA' },
        position: { x: 400, y: 350 },
    },
    {
        id: 'tasks',
        type: 'node',
        data: { label: 'Assign Tasks', backgroundColor: '#FF4500' },
        position: { x: 250, y: 350 },
    },
    {
        id: 'monitor',
        type: 'node',
        data: { label: 'Monitor Progress', backgroundColor: '#BA55D3' },
        position: { x: 400, y: 450 },
    },
    {
        id: 'communication',
        type: 'node',
        data: { label: 'Team Communication', backgroundColor: '#00CED1' },
        position: { x: 550, y: 450 },
    },

    {
        id: 'closure',
        type: 'node',
        data: { label: 'Closure', backgroundColor: '#DAA520' },
        position: { x: 800, y: 350 },
    },
    {
        id: 'deliverables',
        type: 'node',
        data: { label: 'Final Deliverables', backgroundColor: '#1E90FF' },
        position: { x: 800, y: 450 },
    },
    {
        id: 'review',
        type: 'node',
        data: { label: 'Project Review', backgroundColor: '#32CD32' },
        position: { x: 950, y: 450 },
    },
    {
        id: 'documentation',
        type: 'node',
        data: { label: 'Documentation', backgroundColor: '#FF69B4' },
        position: { x: 1050, y: 550 },
    },
];

const initialEdges = [
    { id: 'e1', source: 'root', target: 'initiation', type: 'smoothstep' },
    { id: 'e2', source: 'root', target: 'planning', type: 'smoothstep' },
    { id: 'e3', source: 'root', target: 'execution', type: 'smoothstep' },
    { id: 'e4', source: 'root', target: 'closure', type: 'smoothstep' },

    { id: 'e5', source: 'initiation', target: 'goal', type: 'smoothstep' },
    { id: 'e6', source: 'initiation', target: 'feasibility', type: 'smoothstep' },
    { id: 'e7', source: 'initiation', target: 'stakeholders', type: 'smoothstep' },

    { id: 'e8', source: 'planning', target: 'resources', type: 'smoothstep' },
    { id: 'e9', source: 'planning', target: 'schedule', type: 'smoothstep' },
    { id: 'e10', source: 'planning', target: 'risks', type: 'smoothstep' },

    { id: 'e11', source: 'execution', target: 'tasks', type: 'smoothstep' },
    { id: 'e12', source: 'execution', target: 'monitor', type: 'smoothstep' },
    { id: 'e13', source: 'execution', target: 'communication', type: 'smoothstep' },

    { id: 'e14', source: 'closure', target: 'deliverables', type: 'smoothstep' },
    { id: 'e15', source: 'closure', target: 'review', type: 'smoothstep' },
    { id: 'e16', source: 'closure', target: 'documentation', type: 'smoothstep' },
];
const Projectmap = () => {
    const {createMap}=useMindmapStore();
    const[name,setName]=useState('')
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [selectedShape, setSelectedShape] = useState('rectangle');
   
    const saveMap = async () => {
        try {
            const mindmap = { nodes, edges };
            await createMap({ mindmap: mindmap, name: name });
            Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
        } catch (error) {
            console.error('Failed to save flowchart to API', error);
            Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
        }
    }
   
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const addNode = (shape) => {
        const newNode = {
            id: `${nodes.length + 1}`,
            type: 'node',
            data: {
                label: `node ${nodes.length + 1}`, shape: shape || selectedShape,
                backgroundColor: getRandomColor()
            },
            position: { x: 250, y: 250 },
            style: { backgroundColor: 'transparent' },
        };
        setNodes((nds) => nds.concat(newNode));
    };

   const addEdgeDynamically = () => {
        if (nodes.length < 2) {
            alert('Add at least two nodes to create an edge.');
            return;
        }
        const newEdge = {
            id: `e${nodes.length - 1}-${nodes.length}`,
            source: `${nodes.length - 1}`,
            target: `${nodes.length}`,
            
        };
        setEdges((eds) => eds.concat(newEdge));
    };

    const removeSelectedElements = () => {

        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedEdges = edges.filter((edge) => edge.selected);

        if (selectedNodes.length === 0 && selectedEdges.length === 0) {
            alert('Select a node or edge to remove.');
            return;
        }
        const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
        const updatedNodes = nodes.filter((node) => !node.selected);

        const updatedEdges = edges.filter(
            (edge) =>
                !selectedEdges.includes(edge) &&
                !selectedNodeIds.has(edge.source) &&
                !selectedNodeIds.has(edge.target)
        );

        setNodes(updatedNodes);
        setEdges(updatedEdges);
    };

    return (
          <div style={{ height: '100vh', width: '100%' }}>
        <div className='map'>    <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >

                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow></div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Project Mindmap"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
            </div>
            <div className="responsive">
                <div className="buttons-container">
                    <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
                        <FiSquare size={20} />
                    </button>
                    <button onClick={() => addNode('oval')} title="Add Oval Node">
                        <FaRegCircle size={20} />
                    </button>
                    <button onClick={() => addNode('diamond')} title="Add Diamond Node">
                        <GoDiamond size={20} />
                    </button>
                    <button onClick={addEdgeDynamically} title="Add Edge">
                        <FaLink size={20} />
                    </button>
                    <button onClick={removeSelectedElements} title="Remove Selected">
                        <FaTrash size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} /></button>
                </div>
            </div>
        </div>
    );
};

export default Projectmap;