import React, { useState, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa6';
import { MdOutlineViewKanban } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RiTeamLine } from 'react-icons/ri';
import { MdLogout } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { PiMemberOfBold } from 'react-icons/pi';
import { AiOutlineProject } from "react-icons/ai";
import { useProjectStore } from '../Store/ProjectStore/ProjectStore';


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState('Select Project');
  const { getProject, project } = useProjectStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState(location.pathname);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getProject();
      } catch (error) {
        console.error("Failed to fetch projects", error);
      }
    };

    fetchProjects();
  }, [getProject]);

  const storedProjectId = localStorage.getItem("ProjectId");
  useEffect(() => {
        if (storedProjectId && project.length>0) {
          const storedProject = project.find(p => p._id === storedProjectId);
          if (storedProject) {
            setSelectedProject(storedProject.projectname);
          } else {
            localStorage.removeItem("ProjectId");
          }
        }
  }, [project]);



  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('ProjectId');
    localStorage.removeItem('drawingId');
    localStorage.removeItem('draggableId');
    localStorage.removeItem('flowchartId');
    localStorage.removeItem('chartId');
    localStorage.removeItem('Id');
    localStorage.removeItem('loginEmail');
    sessionStorage.clear();
    navigate('/login');
  };

  const handleSelectProject = (project) => {
    if (project && project._id) {

      setIsOpen(false);
      localStorage.setItem("ProjectId", project._id);
      setSelectedProject(project.projectname);
      window.location.reload();
    } else {
      setSelectedProject('Select Project');
      localStorage.removeItem("ProjectId");
    }
  };

  const handleMenuItemClick = (path) => {
    setSelectedMenuItem(path);
  };

  return (
    <div className="sidebar">
      <div className="">
        <img
          src="\Whiteboard Logo PNG Black.png"
          className="flex justify-center text-black items-center ml-2 mt-8 mb-8"
          alt="Whiteboard Logo"
          style={{ height: "24px", marginLeft: "19px" }}
        />
        <div className="dropdown ml-4">
          <button onClick={toggleDropdown} className="dropdown-button menu-item border border-gray-300">
            {selectedProject}
            <svg
              className={`w-4 h-4 ml-2 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {isOpen && (
            <ul className="dropdown-menu">
              <li
                onClick={() => handleSelectProject(null)}
                className={`menu-item ${selectedProject === 'Select Project' ? 'bg-gray-300' : ''}`}
              >
                Select Project
              </li>
              {project.map((p) => (
                <li
                  key={p._id}
                  onClick={() => handleSelectProject(p)}
                  className={`menu-item ${selectedProject === p.projectname ? 'bg-gray-300' : ''}`}
                >
                  {p.projectname}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <ul className="flex flex-col space-y-2 px-4 mt-4">
        <Link
          to="boards"
          className={`menu-item ${selectedMenuItem === '/boards' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/boards')}
        >
          <MdOutlineViewKanban size={20} className="mt-[2px]" />
          <li>Task</li>
        </Link>
        <Link
          to=""
          className={`menu-item ${selectedMenuItem === '/' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/')}
        >
          <FaRegClock size={20} className="pt-1" />
          <li>Board</li>
        </Link>
        <Link
          to="teams"
          className={`menu-item ${selectedMenuItem === '/teams' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/teams')}
        >
          <RiTeamLine size={20} className="mt-[2px]" />
          <li>Team</li>
        </Link>
        <Link
          to="members"
          className={`menu-item ${selectedMenuItem === '/members' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/members')}
        >
          <PiMemberOfBold size={15} className="mt-1" />
          <li>Members</li>
        </Link>
        <Link
          to="projects"
          className={`menu-item ${selectedMenuItem === '/projects' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/projects')}
        >
          <AiOutlineProject size={15} className="mt-1" />
          <li>Projects</li>
        </Link>
        <Link
          to="profile"
          className={`menu-item ${selectedMenuItem === '/profile' ? 'bg-gray-300' : ''}`}
          onClick={() => handleMenuItemClick('/profile')}
        >
          <CgProfile size={15} className="mt-1" />
          <li>Profile</li>
        </Link>
        <div className="menu-item">
          <MdLogout size={15} className="mt-1" />
          <li onClick={Logout}>Logout</li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
