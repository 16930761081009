import axios from "axios";
import { create } from "zustand";
import { apiUrl } from "../../Config";

export const  useFlowchartStore= create((set,get)=>({
    currentChart:null,
    flowchart:[],    
    selectedChartId: null, 
  setSelectedChartId: (id) => set({ selectedChartId: id }),
    createChart:async(payload)=>{
        const userId=localStorage.getItem('userId');
        const token=localStorage.getItem('token');
        try{
            const response=await axios.post(
                `${apiUrl}/flowchart/${userId}`,
                payload,{
                    headers:{
                        Authorization:`Bearer ${token}`,
                    },
                }
            );
            console.log('flowchart added successfully',response.data);
          
         
           
            return response.data;
        }catch(err){
            console.error('failed to create flowchart',err);
            throw err;
        }
    },

     getChart :async () => {
        const userId= localStorage.getItem('userId');
        const projectId=localStorage.getItem('ProjectId')
        const token = localStorage.getItem('token');
    
        try {
            const response = await axios.get(
                `${apiUrl}/getflowchart/${userId}/${projectId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            console.log('Flowchart fetched successfully:', response.data);
            return response.data; // Return the data as-is or adapt as needed
        } catch (err) {
            console.error('Failed to get flowchart:', err);
            return null;
        }
    },
    
    getChartId:async()=>{
       const chartId=localStorage.getItem('chartId');       
        const token=localStorage.getItem('token');
        try{
          const response=await axios.get(`${apiUrl}/getflowchartBy/${chartId}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        }catch(err){
            console.error('failed to fetch flowchart:',err);
            throw err;
        }
    },

    deleteChart: async (chartId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.delete(
                `${apiUrl}/deleteflowchart/${chartId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (err) {
            console.error('Failed to delete chart:', err.response ? err.response.data : err.message);
            throw err;
        }
    },
    updateChart:async(payload)=>{
        const chartId=localStorage.getItem('chartId'); 
        const  token = localStorage.getItem('token');
        try{
            const response=await axios.put(`${apiUrl}/updateflowchart/${chartId}`,payload,{
                headers:{
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return response.data;
            
        }catch(err){
            console.error('failed to update flowchart:',err);
            throw err;
        }
    },

    setCurrentChart:(chart)=> set({currentChart:chart})
    
}))