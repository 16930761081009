
import React, { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import './main.css'
import { FiSquare } from 'react-icons/fi';
import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';
import CustomNode from './CustomNode';
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';
import Swal from 'sweetalert2';
const nodeTypes = {
    node: CustomNode,
}


const initialNodes = [
  { 
    id: 'root', 
    data: { label: 'Learning Path' ,backgroundColor: '#f39c12',}, 
    position: { x: 1000, y: 1 }, 
    type: 'node', 
    style: { fontWeight:'bold' }
  },
  { 
    id: 'frontend', 
    data: { label: 'Frontend Development' ,backgroundColor:'#3498db'}, 
    position: { x: 350, y: 200 }, 
    type: 'node', 
    
  },
  { 
    id: 'html', 
    data: { label: 'HTML' ,backgroundColor:'#e74c3c'}, 
    position: { x: 250, y: 350 }, 
    type: 'node', 
   
  },
  { 
    id: 'css', 
    data: { label: 'CSS',backgroundColor:'#2ecc71' }, 
    position: { x: 400, y: 350 }, 
    type: 'node', 
    
  },
  { 
    id: 'javascript', 
    data: { label: 'JavaScript',backgroundColor:'#f1c40f' }, 
    position: { x: 550, y: 350 }, 
    type: 'node', 
    
  },
  { 
    id: 'react', 
    data: { label: 'React',backgroundColor: '#9b59b6'}, 
    position: { x: 700, y: 350 }, 
    type: 'node', 
   
  },
  { 
    id: 'vue', 
    data: { label: 'Vue.js',backgroundColor:'#1abc9c' }, 
    position: { x: 850, y: 350 }, 
    type: 'node', 
    
  },

  { 
    id: 'backend', 
    data: { label: 'Backend Development',backgroundColor:'#34495e' }, 
    position: { x: 850, y: 200 }, 
    type: 'node', 
    
  },
  { 
    id: 'node', 
    data: { label: 'Node.js',backgroundColor: '#2ecc71'}, 
    position: { x: 700, y: 450 }, 
    type: 'node', 
   
  },
  { 
    id: 'express', 
    data: { label: 'Express',backgroundColor: '#34495e'}, 
    position: { x: 850, y: 450 }, 
    type: 'node', 
    
  },
  { 
    id: 'python', 
    data: { label: 'Python',backgroundColor:'#e74c3c' }, 
    position: { x: 1000, y: 450 }, 
    type: 'node', 
    
  },
  { 
    id: 'django', 
    data: { label: 'Django',backgroundColor: '#2ecc71'}, 
    position: { x: 1150, y: 450 }, 
    type: 'node', 
    
  },

  { 
    id: 'database', 
    data: { label: 'Database Management',backgroundColor:'#1abc9c' }, 
    position: { x: 1200, y: 200 }, 
    type: 'node', 
   
  },
  { 
    id: 'sql', 
    data: { label: 'SQL',backgroundColor: '#3498db'}, 
    position: { x: 1050, y: 350 }, 
    type: 'node', 
    
  },
  { 
    id: 'nosql', 
    data: { label: 'NoSQL',backgroundColor:'#e74c3c' }, 
    position: { x: 1200, y: 350 }, 
    type: 'node', 

  },

  { 
    id: 'devops', 
    data: { label: 'DevOps' ,backgroundColor:'#f39c12'}, 
    position: { x: 1350, y: 200 }, 
    type: 'node', 
  
  },
  { 
    id: 'ciCD', 
    data: { label: 'CI/CD',backgroundColor:'#34495e' }, 
    position: { x: 1400, y: 450 }, 
    type: 'node', 
   
  },
  { 
    id: 'cloud', 
    data: { label: 'Cloud Computing',backgroundColor: '#3498db'}, 
    position: { x: 1400, y: 350 }, 
    type: 'node', 
    
  },
  { 
    id: 'docker', 
    data: { label: 'Docker',backgroundColor:'#1abc9c' }, 
    position: { x: 1550, y: 350 }, 
    type: 'node', 
   
  },
  { 
    id: 'kubernetes', 
    data: { label: 'Kubernetes',backgroundColor:'#9b59b6' }, 
    position: { x: 1700, y: 350 }, 
    type: 'node', 
   
  }
];

const initialEdges = [
  { id: 'e1-frontend', source: 'root', target: 'frontend'},
  { id: 'e1-backend', source: 'root', target: 'backend'},
  { id: 'e1-devops', source: 'root', target: 'devops'},
  
  { id: 'e2-html', source: 'frontend', target: 'html'},
  { id: 'e2-css', source: 'frontend', target: 'css'},
  { id: 'e2-javascript', source: 'frontend', target: 'javascript'},
  { id: 'e2-react', source: 'frontend', target: 'react'},
  { id: 'e2-vue', source: 'frontend', target: 'vue'},

  { id: 'e3-node', source: 'backend', target: 'node'},
  { id: 'e3-express', source: 'backend', target: 'express'},
  { id: 'e3-python', source: 'backend', target: 'python'},
  { id: 'e3-django', source: 'backend', target: 'django'},

  { id: 'e4-database', source: 'backend', target: 'database'},
  { id: 'e5-sql', source: 'database', target: 'sql'},
  { id: 'e5-nosql', source: 'database', target: 'nosql'},

  { id: 'e6-ciCD', source: 'devops', target: 'ciCD'},
  { id: 'e6-cloud', source: 'devops', target: 'cloud'},
  { id: 'e6-docker', source: 'devops', target: 'docker'},
  { id: 'e6-kubernetes', source: 'devops', target: 'kubernetes'},
]; 

const Learningmap = () => {
  const {createMap}=useMindmapStore();
  const[name,setName]=useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [selectedShape, setSelectedShape] = useState('rectangle');
   
    const saveMap = async () => {
      try {
          const mindmap = { nodes, edges };
          await createMap({ mindmap: mindmap, name: name });
          Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
      } catch (error) {
          console.error('Failed to save flowchart to API', error);
          Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
      }
  }
   
   
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const addNode = (shape) => {     
        const newNode = {
            id: `${nodes.length + 1}`,
            type: 'node',
            data: { label: `node ${nodes.length + 1}`, shape: shape || selectedShape,
            backgroundColor:getRandomColor() },
            position: { x: 250, y: 250 },
            style: { backgroundColor:'transparent' },
        };
        setNodes((nds) => nds.concat(newNode));
    };

   const addEdgeDynamically = () => {
        if (nodes.length < 2) {
            alert('Add at least two nodes to create an edge.');
            return;
        }
        const newEdge = {
            id: `e${nodes.length - 1}-${nodes.length}`,
            source: `${nodes.length - 1}`,
            target: `${nodes.length}`,
            
        };
        setEdges((eds) => eds.concat(newEdge));
    };

    const removeSelectedElements = () => {

        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedEdges = edges.filter((edge) => edge.selected);

        if (selectedNodes.length === 0 && selectedEdges.length === 0) {
            alert('Select a node or edge to remove.');
            return;
        }
        const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
        const updatedNodes = nodes.filter((node) => !node.selected);

        const updatedEdges = edges.filter(
            (edge) =>
                !selectedEdges.includes(edge) &&
                !selectedNodeIds.has(edge.source) &&
                !selectedNodeIds.has(edge.target)
        );

        setNodes(updatedNodes);
        setEdges(updatedEdges);
    };

    return (
          <div style={{ height: '100vh', width: '100%' }}>
         <div className='map'>   <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >

                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
            </div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Learning Mindmap"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
            </div>
            <div className="responsive">
                <div className="buttons-container">
                    <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
                        <FiSquare size={20} />
                    </button>
                    <button onClick={() => addNode('oval')} title="Add Oval Node">
                        <FaRegCircle size={20} />
                    </button>
                    <button onClick={() => addNode('diamond')} title="Add Diamond Node">
                        <GoDiamond size={20} />
                    </button>
                    <button onClick={addEdgeDynamically} title="Add Edge">
                        <FaLink size={20} />
                    </button>
                    <button onClick={removeSelectedElements} title="Remove Selected">
                        <FaTrash size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} />
                    </button>
           
                </div>
            </div>
        </div>
    );
};
 
export default Learningmap;
