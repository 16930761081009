import React, { useState, useEffect } from 'react'
import { IoSearch } from "react-icons/io5";
import moment from "moment";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useAccountStore } from "../Store/AccountStore/AccountStore";
const MobileMemberTable = () => {
  const [users, setUsers] = useState([]); // Ensure users is initialized as an empty array
  const { subUser } = useAccountStore();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await subUser();
        setUsers(response);
        console.log("Users fetched successfully", response);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, [subUser]);

  return (
    <div className=" bg-gray-100 md:hidden">
      <div className="max-w-full mx-auto rounded-lg p-2">
        <div className="bg-blue-600 text-white p-3 rounded mb-4">
          <span>1 Member below is in the list.</span>
        </div>

        <div className="search-container flex items-center border border-gray-300 rounded-md p-2 mb-4">
          <IoSearch size={25} />
          <input
            type="text"
            placeholder="Search Member"
            className="search-input ml-2 outline-none flex-1"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.map((user) => (
            <div key={user.id} className="bg-white p-4 rounded-lg shadow-md">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center text-xl font-semibold text-gray-600 mr-4">
                  {user.name.charAt(0)}
                </div>
                <div>
                  <p className="font-semibold">{user.name}</p>
                  <p className="text-gray-500">{user.email}</p>
                </div>
              </div>
              <div className="text-gray-600 mb-4">
                <p className="text-sm">Created Date: {moment(user.created_at).format("LLL")}</p>
                <p className="text-sm">Created By:  {user.user_type === '0' ? "Admin" : "User"}</p>
                {/* <p className="text-sm">Created By: {user.Board}</p> */}
                {/* <p className="text-sm justify-between flex">
                  Status: <span className="bg-blue-200 text-blue-800 font-bold px-2 py-1 rounded-md shadow-md">{user.status}</span>
                </p> */}

              </div>
              <div className="flex justify-end space-x-2">
                <button className="p-2 text-blue-500 hover:bg-gray-200 rounded-md">
                  <FiEdit size={20} />
                </button>
                <button className="p-2 text-red-500 hover:bg-gray-200 rounded-md">
                  <FiTrash2 size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MobileMemberTable