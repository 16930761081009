import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation


const SuccessModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleOkClick = () => {
    onClose(); // Close the modal
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="modal-overlay-popup">
      <div className="modal-content-popup">
        <h2>Registration Successful</h2>
        <p>Please login to continue.</p>
        <button onClick={handleOkClick}>OK</button>
      </div>
    </div>
  );
};

export default SuccessModal;
