import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';

const CustomNode = ({ id, data, isConnectable, onNodeChange }) => {
  const [label, setLabel] = useState(data.label || '');
  const [isEditing, setIsEditing] = useState(false);
  const { shape,backgroundColor } = data;

  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return {
          borderRadius: '50%',
          width: '100px',
          height: '60px',
          padding: '10px',
          wordWrap: 'break-word', 
          overflow: 'hidden',
          backgroundColor: backgroundColor, 
          textOverflow: 'ellipsis',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        };
      case 'diamond':
        return {
          width: '80px',
          height: '80px',
          padding: '10px',
          backgroundColor: backgroundColor, 
          transform: 'rotate(45deg)',
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        };
      case 'rectangle':
      default:
        return {
          borderRadius: '5px',
          width: '120px',
          height: '60px',
          padding: '10px',
          wordWrap: 'break-word',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor:backgroundColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        };
    }
  };

  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: 'rotate(-45deg)', // Ensures correct handle position despite diamond rotation
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    data.label = newLabel;
    if (onNodeChange) {
      onNodeChange(id, newLabel);
    }
  };

  
  const handleFocus = () => setIsEditing(true);
  const handleBlur = () => setIsEditing(false);

  return (
    <div style={getShapeStyle()}>
      {isEditing ? (
        <input
          type="text"
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{ width: '100%', textAlign: 'center', backgroundColor: 'transparent', border: 'none', outline: 'none' }}
        />
      ) : (
        <div onDoubleClick={handleFocus}>
          {label}
        </div>
      )}


      <Handle type="target" position="top" isConnectable={isConnectable} style={handleStyles('top')} />
      <Handle type="source" position="bottom" isConnectable={isConnectable} style={handleStyles('bottom')} />
    </div>
  );
};

export default CustomNode;
