import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './Swimlance.css'; 

const SwimlaneNode = ({ id, data, isConnectable }) => {
  const [label, setLabel] = useState(data.label || '');

  // Determine the shape from data or default to 'rectangle'
  const shape = data.shape || 'rectangle';

  const handleChange = (event) => {
    setLabel(event.target.value);
    data.label = event.target.value;
  };

  // Define styles for different shapes
  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return {
          borderRadius: '50%',
          width: '100px',
          height: '60px',
        };
      case 'diamond':
        return {
          transform: 'rotate(45deg)',
          width: '80px',
          height: '80px',
        };
      case 'rectangle':
      default:
        return {
          borderRadius: '5px',
          width: '120px',
          height: '60px',
        };
    }
  };

  // Define handle styles based on shape and position
  const handleStyles = (position) => {
    return {
      position: 'absolute',
      background: '#555',
      borderRadius: '50%',
      // width: '10px',
      // height: '10px',
      zIndex: 10,
      top: position === 'top' ? '10%' : 'auto',
      bottom: position === 'bottom' ? '10%' : 'auto',
      left: position === 'left' ? '10%' : 'auto',
      right: position === 'right' ? '10%' : 'auto',
      transform: shape === 'diamond' && position === 'top' ? 'rotate(-45deg)' : 'none',
    };
  };

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        type="text"
        value={label}
        onChange={handleChange}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
      />
      <Handle type="target" position="top" style={handleStyles('top')} isConnectable={isConnectable} />
      <Handle type="source" position="bottom" style={handleStyles('bottom')} isConnectable={isConnectable} />
    </div>
  );
};

export default SwimlaneNode;
