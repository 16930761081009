import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useTeamStore } from '../Store/TeamStore/TeamStore';

const EditTeamModal = ({ isOpen, onClose, team, onSave }) => {
  const [teamName, setTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { updateTeam, setCurrentTeam } = useTeamStore();

  useEffect(() => {
    if (team) {
      setTeamName(team.teamname);
      setCurrentTeam(team); // Ensure current team is set
    }
  }, [team, setCurrentTeam]);

  if (!isOpen) return null;

  const handleSave = async () => {
    if (!teamName) {
      setError('Team name is required');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await updateTeam({
        teamname: teamName, // Pass the updated team name
      });

      onSave(); // Notify parent component to refresh data
      onClose(); // Close the modal after successful update
    } catch (err) {
      console.error('Failed to update team:', err);
      setError('Failed to update team. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="modal-header flex justify-between items-center">
          <h3 className="modal-title text-lg font-semibold">Edit Team</h3>
          <button
            onClick={onClose}
            type="button"
            className="modal-close text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="modal-body mt-4">
          <div className="space-y-4">
            <p className="modal-description text-gray-600">
              Update the team name as needed.
            </p>
            <input
              type="text"
              name="teamName"
              id="teamName"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              className="modal-input border border-gray-300 rounded px-3 py-2 w-full"
              placeholder="Enter team name"
              required
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div className="flex space-x-4 mt-4">
              <button
                type="button"
                onClick={handleSave}
                className={`modal-button-primary bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
              <button
                type="button"
                className="modal-button-secondary bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTeamModal;
