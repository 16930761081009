import { create } from "zustand";
import axios from "axios";
import { apiUrl } from "../../Config";

export const useTeamStore = create((set, get) => ({
    currentTeam: null,
    teams:[],
    createTeam: async (payload) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        
        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        try {
            const response = await axios.post(
                `${apiUrl}/team/${userId}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Team created successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to create team:', err);
            throw err;
        }
    },

    getTeam: async () => {
        const projectId = localStorage.getItem('ProjectId');
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        try {
            const response = await axios.get(
                `${apiUrl}/getteam/${projectId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Team fetched successfully:', response.data);
            set({teams:response.data})
        } catch (err) {
            console.error('Failed to fetch team:', err);
            throw err;
        }
    },
 
    searchTeam: async (teamname) => {
        const projectId = localStorage.getItem('ProjectId');
        const token = localStorage.getItem('token');
    
        if (!projectId || projectId === 'null') {
            console.error('Invalid ProjectId:', projectId);
            return; // Return early if the projectId is invalid
        }
    
        try {
            const response = await axios.get(
                `${apiUrl}/searchteams/${projectId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: { teamname }, // Pass teamname as a query parameter
                }
            );
            console.log(response);
            set({ team: response.data });
        } catch (err) {
            console.error('Failed to fetch team:', err);
            throw err;
        }
    },
    
    
    
    updateTeam: async (payload) => {
        const { currentTeam } = get(); // Access currentTeam from Zustand store
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }

        if (!currentTeam || !currentTeam._id) {
            throw new Error('Current team or team ID is missing.');
        }

        try {
            const response = await axios.put(
                `${apiUrl}/updateteam/${currentTeam._id}`, // Ensure this is your actual API endpoint
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Team updated successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to update team:', err);
            throw err;
        }
    },
   
    deleteTeam: async (teamId) => {
       
        const token = localStorage.getItem('token');

        if (!token) {
            throw new Error('Authorization token is missing.');
        }
    try {
            const response = await axios.delete(
                `${apiUrl}/deleteteam/${teamId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Team deleted successfully:', response.data);
            return response.data;
        } catch (err) {
            console.error('Failed to delete team:', err);
            throw err;
        }
    },

    
    setCurrentTeam: (team) => set({ currentTeam: team }), // Add method to set the current team
}));
