
import React, { useState, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import './main.css'
import Swal from 'sweetalert2';
import { useMindmapStore } from '../../Store/MindmapStore/MindmapStore';
import { FiSquare } from 'react-icons/fi';
import { GoDiamond } from 'react-icons/go';
import { FaLink } from 'react-icons/fa';
import { FaRegCircle, FaSave, FaTrash } from 'react-icons/fa'
import CustomNode from './CustomNode';
const nodeTypes = {
    node: CustomNode,
}

const initialNodes = [
    {
        id: 'root', type: 'node',
        data: { label: 'Personal Goals', backgroundColor: 'pink' },
        position: { x: 400, y: 1 },
        style: { fontWeight: 'bold' }
    },

    {
        id: 'health', type: 'node',
        data: { label: 'Health & Fitness', backgroundColor: '#32CD32' },
        position: { x: 100, y: 100 },
    },
    {
        id: 'diet',
        type: 'node',
        data: { label: 'Nutrition', backgroundColor: '#98FB98' },
        position: { x: 20, y: 200 }
    },
    {
        id: 'workout',
        type: 'node',
        data: { label: 'Regular Workouts', backgroundColor: '#98FB98' },
        position: { x: 180, y: 200 },
    },
    {
        id: 'meditation',
        type: 'node',
        data: { label: 'Meditation', backgroundColor: '#98FB98' },
        position: { x: 95, y: 300 },
    },

    {
        id: 'career',
        type: 'node',
        data: { label: 'Career Development', backgroundColor: '#4682B4' },
        position: { x: 400, y: 100 },
    },
    {
        id: 'skills',
        type: 'node',
        data: { label: 'Develop Skills', backgroundColor: '#B0E0E6' },
        position: { x: 300, y: 220 },
    },
    {
        id: 'networking',
        type: 'node',
        data: { label: 'Networking', backgroundColor: '#B0E0E6' },
        position: { x: 490, y: 220 },
    },
    {
        id: 'certification',
        type: 'node',
        data: { label: 'Obtain Certifications', backgroundColor: '#B0E0E6' },
        position: { x: 400, y: 320 },
    },

    {
        id: 'finance',
        type: 'node',
        data: { label: 'Financial Goals', backgroundColor: '#FF4500' },
        position: { x: 700, y: 100 },
    },
    {
        id: 'savings',
        type: 'node',
        data: { label: 'Savings Plan', backgroundColor: '#FF6347' },
        position: { x: 620, y: 200 },
    },
    {
        id: 'investment',
        type: 'node',
        data: { label: 'Investments', backgroundColor: '#FF6347' },
        position: { x: 790, y: 200 },
    },
    {
        id: 'budget',
        type: 'node',
        data: { label: 'Budget Management', backgroundColor: '#FF6347' },
        position: { x: 700, y: 300 },
    },


    {
        id: 'hobbies',
        type: 'node',
        data: { label: 'Hobbies', backgroundColor: '#DAA520' },
        position: { x: 400, y: 400 },
    },
    {
        id: 'music',
        type: 'node',
        data: { label: 'Learn an Instrument', backgroundColor: '#FFD700' },
        position: { x: 300, y: 500 },
    },
    {
        id: 'art',
        type: 'node',
        data: { label: 'Painting/Drawing', backgroundColor: '#FFD700' },
        position: { x: 490, y: 500 },
    },
    {
        id: 'travel',
        type: 'node',
        data: { label: 'Travel the World', backgroundColor: '#FFD700' },
        position: { x: 400, y: 600 },
    },
];



const initialEdges = [
    { id: 'e1-2', source: 'root', target: 'health' },
    { id: 'e2-3', source: 'health', target: 'diet' },
    { id: 'e2-4', source: 'health', target: 'workout' },
    { id: 'e2-5', source: 'health', target: 'meditation' },

    { id: 'e1-6', source: 'root', target: 'career' },
    { id: 'e6-7', source: 'career', target: 'skills' },
    { id: 'e6-8', source: 'career', target: 'networking' },
    { id: 'e6-9', source: 'career', target: 'certification' },

    { id: 'e1-10', source: 'root', target: 'finance' },
    { id: 'e10-11', source: 'finance', target: 'savings' },
    { id: 'e10-12', source: 'finance', target: 'investment' },
    { id: 'e10-13', source: 'finance', target: 'budget' },

    { id: 'e1-14', source: 'root', target: 'hobbies' },
    { id: 'e14-15', source: 'hobbies', target: 'music' },
    { id: 'e14-16', source: 'hobbies', target: 'art' },
    { id: 'e14-17', source: 'hobbies', target: 'travel' },
];

const Personalmap = () => {
    const [name, setName] = useState('');
    const { createMap } = useMindmapStore();
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    const [selectedShape, setSelectedShape] = useState('rectangle');

    const saveMap = async () => {
        try {
            const mindmap = { nodes, edges };
            await createMap({ mindmap: mindmap, name: name });
            Swal.fire('Success!', 'Flowchart saved successfully!', 'success'); // SweetAlert for success
        } catch (error) {
            console.error('Failed to save flowchart to API', error);
            Swal.fire('Error!', 'Failed to save flowchart.', 'error'); // SweetAlert for error
        }
    }

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const addNode = (shape) => {
        const newNode = {
            id: `${nodes.length + 1}`,
            type: 'node',
            data: {
                label: `node ${nodes.length + 1}`, shape: shape || selectedShape,
                backgroundColor: getRandomColor()
            },
            position: { x: 250, y: 250 },
            style: { backgroundColor: 'transparent' },
        };
        setNodes((nds) => nds.concat(newNode));
    };

   const addEdgeDynamically = () => {
        if (nodes.length < 2) {
            alert('Add at least two nodes to create an edge.');
            return;
        }
        const newEdge = {
            id: `e${nodes.length - 1}-${nodes.length}`,
            source: `${nodes.length - 1}`,
            target: `${nodes.length}`,
            
        };
        setEdges((eds) => eds.concat(newEdge));
    };

    const removeSelectedElements = () => {

        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedEdges = edges.filter((edge) => edge.selected);

        if (selectedNodes.length === 0 && selectedEdges.length === 0) {
            alert('Select a node or edge to remove.');
            return;
        }
        const selectedNodeIds = new Set(selectedNodes.map((node) => node.id));
        const updatedNodes = nodes.filter((node) => !node.selected);

        const updatedEdges = edges.filter(
            (edge) =>
                !selectedEdges.includes(edge) &&
                !selectedNodeIds.has(edge.source) &&
                !selectedNodeIds.has(edge.target)
        );

        setNodes(updatedNodes);
        setEdges(updatedEdges);
    };

    return (
        <div style={{ height: '100vh', width: '100%' }}>
          <div className='map'>  <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
            >

                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow></div>
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Personal Mindmap"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
            </div>
            <div className="responsive">
                <div className="buttons-container">
                    <button onClick={() => addNode('rectangle')} title="Add Rectangle Node">
                        <FiSquare size={20} />
                    </button>
                    <button onClick={() => addNode('oval')} title="Add Oval Node">
                        <FaRegCircle size={20} />
                    </button>
                    <button onClick={() => addNode('diamond')} title="Add Diamond Node">
                        <GoDiamond size={20} />
                    </button>
                    <button onClick={addEdgeDynamically} title="Add Edge">
                        <FaLink size={20} />
                    </button>
                    <button onClick={removeSelectedElements} title="Remove Selected">
                        <FaTrash size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} />
                    </button>
                    <button onClick={saveMap} title='Save Mindmap'><FaSave size={20} /></button>
                </div>
            </div>
        </div>
    );
};

export default Personalmap;
