
import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './Dataflow.css';

const DataFlowNode = ({ id, data, isConnectable }) => {
  const [label, setLabel] = useState(data.label || '');
  const [isEditing, setIsEditing] = useState(false);

  const { shape } = data; // Assuming shape is passed in data

  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px' };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px' };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px' };
    }
  };

  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };

  const handleChange = (event) => {
    setLabel(event.target.value);
    data.label = event.target.value; // Update the node's data
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div
      className={`data-flow-node ${isEditing ? 'editing' : ''}`}
      style={{
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
    >
      <input
        type="text"
        value={label}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="data-flow-node-input"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
      />
      <Handle type="target" position="left" style={handleStyles('left')} isConnectable={isConnectable} />
      <Handle type="source" position="right" style={handleStyles('right')} isConnectable={isConnectable} />
    </div>
  );
};

export default DataFlowNode;
