import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useProjectStore } from '../Store/ProjectStore/ProjectStore'; // Import the project store

const CreateProjectModal = ({ isOpen, onClose, onSave }) => {
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { createProject, getProject } = useProjectStore(); // Get the createProject function from the store

  if (!isOpen) return null;

  const handleCreateProject = async () => {
    if (!projectName) {
      setError('Project name is required');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Create project using the Zustand store method
      await createProject({ projectname: projectName });
      await getProject();
      onClose();
    } catch (err) {
      console.error('Failed to create project:', err);
      setError('Failed to create project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="modal-content bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="modal-header flex justify-between items-center">
          <h3 className="modal-title text-lg font-semibold">Create new Project</h3>
          <button
            onClick={onClose}
            type="button"
            className="modal-close text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={25} />
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="modal-body mt-4">
          <p className="modal-description text-gray-600">
            Name your project before choosing a new plan subscription.
          </p>
          <input
            type="text"
            name="projectName"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            className="modal-input border border-gray-300 rounded px-3 py-2 w-full"
            placeholder="Enter project name"
            required
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div className="flex space-x-4 mt-4">
            <button
              type="button"
              onClick={handleCreateProject}
              className={`modal-button-primary bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Save'}
            </button>
            <button
              type="button"
              className="modal-button-secondary bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CreateProjectModal;
