import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './CrossFuntion.css';

const CrossFunctionalAndLaneNode = ({ data, id, selected, onNodeChange, isConnectable }) => {
  const [label, setLabel] = useState(data.label);
  const { shape, type } = data;

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setLabel(newLabel);
    onNodeChange(id, newLabel);
  };

  const getShapeStyle = () => {
    switch (shape) {
      case 'oval':
        return { borderRadius: '50%', width: '100px', height: '60px',backgroundColor:"#fff" };
      case 'diamond':
        return { transform: 'rotate(45deg)', width: '80px', height: '80px',backgroundColor:"#fff" };
      case 'rectangle':
      default:
        return { borderRadius: '5px', width: '120px', height: '60px',backgroundColor:"#fff" };
    }
  };

  const handleStyles = (position) => {
    switch (shape) {
      case 'diamond':
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
          transform: 'rotate(-45deg)',
        };
      default:
        return {
          top: position === 'top' ? '10%' : 'auto',
          bottom: position === 'bottom' ? '10%' : 'auto',
          left: position === 'left' ? '10%' : 'auto',
          right: position === 'right' ? '10%' : 'auto',
        };
    }
  };
 
  const renderCrossFunctionalNode = () => (
    <div
      style={{
        position: 'relative',
        padding: '0',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        ...getShapeStyle(),
      }}
      className={`crossFunctional-node ${selected ? 'editing' : ''}`}
    >
      <input
        type="text"
        value={label}
        onChange={handleChange}
        placeholder="Enter node text"
        className="node-input"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          textAlign: 'center',
          backgroundColor: 'transparent',
          padding: 0,
          margin: 0,
          transform: shape === 'diamond' ? 'rotate(-45deg)' : 'none',
        }}
      />
      <Handle type="target" position="top" style={handleStyles('top')} isConnectable={isConnectable} />
      <Handle type="source" position="bottom" style={handleStyles('bottom')} isConnectable={isConnectable} />
    </div>
  );

  const renderLaneNode = () => (
    <div
      style={{
        ...data.style,
        border: '2px solid #ccc',
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      {label}
    </div>
  );

  return type === 'lane' ? renderLaneNode() : renderCrossFunctionalNode();
};

export default CrossFunctionalAndLaneNode;
