import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineViewKanban } from "react-icons/md";
import { FaRegClock, FaRegUser } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { AiOutlineProject } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

const BottomTab = () => {
  const location = useLocation();

  const getLinkClasses = (path) => {
   
    return location.pathname === path
      ? "bg-green-500 rounded-2xl cursor-pointer flex flex-col items-center"
      : "hover:bg-gray-200 rounded cursor-pointer flex flex-col items-center";
  };

  return (
    <ul className="flex justify-around bg-white shadow-md fixed bottom-0 w-full py-2 md:hidden z-50">
      <li className="relative group">
        <Link to="" className={getLinkClasses("/")}>
          <MdOutlineViewKanban size={24} />
        </Link>
        <span className="absolute bottom-full left-0 mb-2 w-20 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Kanbanboard
        </span>
      </li>
      <li className="relative group">
        <Link to="boards" className={getLinkClasses("/boards")}>
          <FaRegClock size={24} />
        </Link>
        <span className="left-0 absolute bottom-full mb-2 w-12 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Boards
        </span>
      </li>
      <li className="relative group">
        <Link to="teams" className={getLinkClasses("/teams")}>
          <RiTeamLine size={24} />
        </Link>
        <span className="absolute bottom-full mb-2 left-0 w-12 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Teams
        </span>
      </li>
      <li className="relative group">
        <Link to="members" className={getLinkClasses("/members")}>
          <FaRegUser size={22} />
        </Link>
        <span className="left-0 absolute bottom-full mb-2 w-14 p-1 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Members
        </span>
      </li>
      <li className="relative group">
        <Link to="projects" className={getLinkClasses("/projects")}>
          <AiOutlineProject size={24} />
        </Link>
        <span className="left-0 absolute bottom-full mb-2 w-12 p-1 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Projects
        </span>
      </li>
      <li className="relative group">
        <Link to="profile" className={getLinkClasses("/profile")}>
          <CgProfile size={24} />
        </Link>
        <span className="left-0 p-1 absolute bottom-full mb-2 w-10 text-center text-xs text-gray-600 bg-white shadow-lg rounded opacity-0 group-hover:opacity-100 transition-opacity">
          Profile
        </span>
      </li>
    </ul>
  );
};

export default BottomTab;
